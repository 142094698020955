import { AlertColor } from "@mui/material/Alert/Alert";
import React, { Dispatch, useCallback, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import ReconciliationListView from "./ReconciliationListView";
import { ReconciliationsStore, ReconciliationsStoreAction } from "./store";
import TransactionConfirmationModal from "./TransactionConfirmationModal";

interface ReconciliationDashboardProps {
  store: ReconciliationsStore;
  updateStore: Dispatch<ReconciliationsStoreAction>;
  queryClient: QueryClient;
  loadPractices: () => Promise<void>;
  practices: PracticeMessage[];
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function ReconciliationDashboard({
  store,
  updateStore,
  queryClient,
  loadPractices,
  practices,
  updateSnackBar,
}: ReconciliationDashboardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [uploadedTransactions, setUploadedTransactions] = useState<
    BankTransactionMessage[]
  >([]);
  const [selectedPracticeId, setSelectedPracticeId] = useState<string>("");

  const loadReconciliations = useCallback(async () => {
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      setIsLoading(true);
      await loadPractices();

      const firstPageResponse = await queryClient.getBankTransactions(1);
      const totalPages = Math.ceil(
        firstPageResponse.total / firstPageResponse.page_size,
      );

      updateStore({
        type: "SET_TRANSACTIONS",
        transactions: firstPageResponse.data as BankTransactionMessage[],
        pagination: {
          total: firstPageResponse.total,
          page: 1,
          pageSize: firstPageResponse.page_size,
          totalPages,
        },
      });

      if (totalPages > 1) {
        const pagePromises = [];
        for (let nextPage = 2; nextPage <= totalPages; nextPage += 1) {
          pagePromises.push(queryClient.getBankTransactions(nextPage));
        }

        const pageResponses = await Promise.all(pagePromises);

        pageResponses.forEach((response) => {
          updateStore({
            type: "APPEND_TRANSACTIONS",
            transactions: response.data as BankTransactionMessage[],
          });
        });
      }
    } catch (error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to load reconciliations. Please try again.",
      });
      // eslint-disable-next-line no-console
      console.error("Error loading reconciliations:", error);
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  }, [loadPractices, queryClient, updateStore]);

  const resetReconciliations = useCallback(() => {
    updateStore({ type: "RESET_TRANSACTIONS" });
  }, [updateStore]);

  const uploadBankTransactions = useCallback(
    async (practiceId: string, file: File) => {
      try {
        const transactions: BankTransactionMessage[] =
          await queryClient.uploadBankTransactions(practiceId, file);

        setUploadedTransactions(transactions);
        setSelectedPracticeId(practiceId);
        setConfirmModalVisible(true);
        updateSnackBar({
          severity: "success",
          message: `Successfully parsed ${transactions.length} transactions. Please review and confirm.`,
        });
      } catch (error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to upload transactions. Please try again.",
        });
        // eslint-disable-next-line no-console
        console.error("Upload bank transactions failed:", error);
      }
    },
    [queryClient, updateSnackBar],
  );

  const reconcileTransactions = useCallback(
    async (transactions: BankTransactionMessage[]) => {
      try {
        const response = await queryClient.reconcileBankTransactions(
          selectedPracticeId,
          transactions,
        );

        resetReconciliations();
        await loadReconciliations();

        const { total, stored, duplicates, matched } = response.summary;
        updateSnackBar({
          severity: "success",
          message: `Successfully reconciled ${total} transactions (${stored} new, ${matched} matched, ${duplicates} duplicates)`,
        });
      } catch (error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to reconcile transactions. Please try again.",
        });
        // eslint-disable-next-line no-console
        console.error("Reconcile bank transactions failed:", error);
      }
    },
    [
      queryClient,
      resetReconciliations,
      loadReconciliations,
      updateSnackBar,
      selectedPracticeId,
    ],
  );

  const updateTransaction = useCallback(
    async (transactionUpdate: BankTransactionUpdateMessage) => {
      try {
        updateStore({
          type: "UPDATE_TRANSACTION",
          transactionUpdate,
        });

        await queryClient.updateBankTransaction(transactionUpdate);
        updateSnackBar({
          severity: "success",
          message: "Transaction updated successfully.",
        });
      } catch (error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to update transaction. Please try again.",
        });
        // eslint-disable-next-line no-console
        console.error("Update transaction failed:", error);

        await loadReconciliations();
      }
    },
    [queryClient, updateStore, loadReconciliations, updateSnackBar],
  );

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ReconciliationListView
              load={loadReconciliations}
              reset={resetReconciliations}
              transactions={store.transactions}
              isLoading={isLoading}
              transactionsLoading={isLoading || !store.loadedTransactions}
              practices={practices}
              uploadBankTransactions={uploadBankTransactions}
              updateTransaction={updateTransaction}
              pagination={store.pagination}
            />
          }
        />
      </Routes>

      <TransactionConfirmationModal
        open={confirmModalVisible}
        onClose={() => setConfirmModalVisible(false)}
        transactions={uploadedTransactions}
        onConfirm={reconcileTransactions}
      />
    </>
  );
}
