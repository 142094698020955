export enum ClaimStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PARTIALLY_DENIED = "PARTIALLY_DENIED",
  PENDING = "PENDING",
}

export enum ReviewStatus {
  ACCEPTED = "ACCEPTED",
  MODIFIED = "MODIFIED",
  REVIEW = "REVIEW",
  BYPASS = "BYPASS",
  ARCHIVED = "ARCHIVED",
  TEST = "TEST",
}

export enum CreatedByActor {
  SYSTEM = "SYSTEM",
  HUMAN = "HUMAN",
}

export enum PREDEFINED_CLAIM_FILTERS {
  REVIEW = "Review",
  DENIALS = "Denials",
  ALL_CLAIMS = "All Claims",
}

export enum PREDEFINED_BANK_TRANSACTION_FILTERS {
  REVIEW = "Review",
  MATCHED = "Matched",
  ALL = "All",
}
