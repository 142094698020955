import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { ClaimStatus, PREDEFINED_CLAIM_FILTERS } from "../../status";
import { getTodayISODate } from "../../utils/utils";
import { useClaimsPolling } from "../claimPolling";
import {
  claimKeyFromProcedureKey,
  deleteClaimAndNotify,
  deleteProcedureAndNotify,
} from "../crud";
import { needsPolling, PostedState, postPMSUpdateAndNotify } from "../posting";
import ClaimsListView from "./ClaimsListView";
import { ClaimsStore, ClaimsStoreAction } from "./store";

interface ClaimsDashboardProps {
  store: ClaimsStore;
  updateStore: Dispatch<ClaimsStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  loadPractices: () => Promise<void>;
  selectedPractices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function ClaimsDashboard({
  store,
  updateStore,
  queryClient,
  updateSnackBar,
  loadPractices,
  selectedPractices,
  supportedPayers,
}: ClaimsDashboardProps) {
  const [isBatchPosting, updateIsBatchPosting] = useState<boolean>(false);
  // We're using isLoadingRef to not accidentally load twice.
  // This is more of an issue with react strict mode for local
  // development.
  const isLoadingRef = useRef(false);
  const [selectedFilter, setSelectedFilter] =
    React.useState<PREDEFINED_CLAIM_FILTERS>(
      PREDEFINED_CLAIM_FILTERS.ALL_CLAIMS,
    );
  const setIsPolling = useClaimsPolling({
    storeClaims: store.claims,
    queryClient,
    updateStore,
    updateSnackBar,
  });

  useEffect(
    () => () => {
      isLoadingRef.current = false;
    },
    [],
  );

  const additionalFilters = (): Record<string, string[]> => {
    const filters: Record<string, string[]> = {};
    if (selectedFilter === PREDEFINED_CLAIM_FILTERS.REVIEW) {
      filters.posted_state = [
        PostedState[PostedState.UNPOSTED],
        PostedState[PostedState.FAILURE],
        PostedState[PostedState.RETRY],
        PostedState[PostedState.PENDING],
        PostedState[PostedState.POSTING],
      ];
    }
    if (selectedFilter === PREDEFINED_CLAIM_FILTERS.DENIALS) {
      filters.claim_status = [
        ClaimStatus[ClaimStatus.DENIED],
        ClaimStatus[ClaimStatus.PARTIALLY_DENIED],
      ];
    }
    return filters;
  };

  const loadClaims = async (signal?: AbortSignal) => {
    if (isLoadingRef.current || signal?.aborted) {
      return;
    }
    try {
      isLoadingRef.current = true;
      if (signal?.aborted) {
        return;
      }
      await loadPractices();
      const searchResponse = await queryClient.getClaimsProceduresAndPatient(
        [],
        undefined,
        signal,
        additionalFilters(),
      );
      if (signal?.aborted) {
        return;
      }
      const claims = searchResponse.data;
      updateStore({
        claims,
        type: "SET_CLAIMS",
      });

      const loadNextPage = async (currentPage: number, totalPages: number) => {
        if (currentPage > totalPages || !isLoadingRef.current) {
          return;
        }
        try {
          const nextPage = await queryClient.getClaimsProceduresAndPatient(
            [],
            currentPage,
            signal,
            additionalFilters(),
          );
          if (signal?.aborted) {
            return;
          }
          updateStore({
            claims: nextPage.data,
            type: "APPEND_CLAIMS",
          });
          if (nextPage.data.some(needsPolling)) {
            const pendingClaims = claims.some(
              (claim) => needsPolling(claim) && !!claim.updatedAt,
            );
            if (pendingClaims) {
              setIsPolling(true);
            }
          }
          setTimeout(() => {
            loadNextPage(currentPage + 1, totalPages);
          }, 100);
        } catch (error) {
          if (
            error &&
            typeof error === "object" &&
            "name" in error &&
            (error.name === "AbortError" || error.name === "CanceledError")
          ) {
            return;
          }
          throw error;
        }
      };
      if (!signal?.aborted) {
        setTimeout(() => {
          loadNextPage(searchResponse.page + 1, searchResponse.total_pages);
        }, 0);
      }
      setIsPolling(claims.some(needsPolling));
    } catch (error: unknown) {
      if (
        error &&
        typeof error === "object" &&
        "name" in error &&
        (error.name === "AbortError" || error.name === "CanceledError")
      ) {
        return;
      }
      throw error;
    }
  };

  const resetClaims = () => {
    updateStore({
      type: "RESET_CLAIMS",
    });
    isLoadingRef.current = false;
  };

  useEffect(() => {
    const abortController = new AbortController();
    const initializeClaims = async () => {
      try {
        await resetClaims();
        await loadClaims(abortController.signal);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to initialize claims:", error);
      }
    };
    initializeClaims();
    return () => {
      abortController.abort(); // Cancel any in-flight requests
    };
  }, [selectedFilter]);

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    await postPMSUpdateAndNotify(
      claim,
      updateStore,
      queryClient,
      updateSnackBar,
      setIsPolling,
    );
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.updateClaim(claimWork);
  };

  const onSaveProcedure = async (procedure: ProcedureMessage) => {
    const claimKey = claimKeyFromProcedureKey(procedure.wieldyId);
    if (!claimKey) {
      updateSnackBar({
        severity: "error",
        message: `Invalid Wieldy ID for procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    const existingClaim = store.claims.find((c) => c.wieldyId === claimKey);
    if (!existingClaim) {
      updateSnackBar({
        severity: "error",
        message: `Claim not found for procedure: ${claimKey}`,
      });
      return;
    }
    const existingProcedure = existingClaim.Procedure.find(
      (p) => p.wieldyId === procedure.wieldyId,
    );
    if (existingProcedure) {
      const deltas = (
        Object.entries(procedure) as [keyof ProcedureMessage, unknown][]
      ).reduce<Partial<ProcedureMessage>>((acc, [key, value]) => {
        // We only need practiceId to update the procedure
        if (key === "practiceId") {
          return acc;
        }
        if (value !== undefined && existingProcedure[key] !== value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (acc[key] as any) = value;
        }
        return acc;
      }, {} as Partial<ProcedureMessage>);
      if (Object.keys(deltas).length === 0) {
        updateSnackBar({
          severity: "warning",
          message: `Nothing to update`,
        });
        return;
      }
      await queryClient.updateProcedure({
        ...deltas,
        wieldyId: procedure.wieldyId,
        practiceId: procedure.practiceId,
      });
      updateStore({
        type: "UPDATE_PROCEDURE",
        claimKey,
        procedure,
      });
      updateSnackBar({
        severity: "success",
        message: `Successfully edited procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    updateStore({
      type: "CREATE_PROCEDURE",
      claimKey,
      procedure,
    });
    await queryClient.createProcedure(procedure);
    updateSnackBar({
      severity: "success",
      message: `Successfully create procedure: ${procedure.wieldyId}`,
    });
  };

  const onSaveClaim = async (claim: ClaimMessage) => {
    const existingClaim = store.claims.find(
      (c) => c.wieldyId === claim.wieldyId,
    );
    if (existingClaim) {
      const deltas = (
        Object.entries(claim) as [keyof ClaimMessage, unknown][]
      ).reduce<Partial<ClaimMessage>>((acc, [key, value]) => {
        const existingValue =
          existingClaim[key] && key === "payer"
            ? (existingClaim[key] as string).toUpperCase()
            : existingClaim[key];
        if (value !== undefined && existingValue !== value) {
          // Updates on the backend currently only accept string
          acc[key] = value;
        }
        return acc;
      }, {} as Partial<ClaimMessage>);
      if (Object.keys(deltas).length === 0) {
        updateSnackBar({
          severity: "warning",
          message: `Nothing to update`,
        });
        return;
      }
      const wieldyId = await queryClient.updateStringifyClaim({
        ...deltas,
        wieldyId: claim.wieldyId,
        practiceId: claim.practiceId,
      });
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...existingClaim,
          ...claim,
        },
      });
      updateSnackBar({
        severity: "success",
        message: `Successfully edited claim: ${wieldyId}`,
      });
      return;
    }

    const wieldyId = await queryClient.createClaim(claim);
    const practice = selectedPractices.find(
      (p) => p.wieldyId === claim.practiceId,
    );
    const createdClaim: ClaimWithProcedureAndPatientMessage = {
      ...(claim as ClaimMessage),
      availableSince: getTodayISODate(),
      practice: practice?.name || "",
      practiceDisplayName: practice?.displayName || "",
      Patient: [],
      Procedure: [],
    };
    updateStore({
      type: "CREATE_CLAIM",
      createdClaim,
    });
    updateSnackBar({
      severity: "success",
      message: `Successfully created claim: ${wieldyId}`,
    });
  };

  const batchPostPMS = async () => {
    try {
      updateIsBatchPosting(true);
      updateSnackBar({
        severity: "info",
        message: "Started batching posting to PMS",
      });
      await queryClient.batchPostToPMS();
      updateSnackBar({
        severity: "success",
        message: "Successfully started batch posting to PMS",
      });
      updateIsBatchPosting(false);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        updateSnackBar({
          severity: "error",
          message: "Failed to start batch posting to PMS",
        });
      }
      updateIsBatchPosting(false);
    }
  };

  const practiceSet = new Set(
    selectedPractices.map((practice) => practice.wieldyId),
  );
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClaimsListView
            reset={resetClaims}
            claims={store.claims.filter((c) => practiceSet.has(c.practiceId))}
            loading={!store.loadedClaims}
            batchPostPMS={batchPostPMS}
            isBatchPosting={isBatchPosting}
            updateClaim={updateClaim}
            postToPMS={postToPMS}
            selectedPractices={selectedPractices}
            onSaveClaim={onSaveClaim}
            onDeleteClaim={async (claim) => {
              await deleteClaimAndNotify(
                claim,
                queryClient,
                updateStore,
                updateSnackBar,
              );
            }}
            onSaveProcedure={onSaveProcedure}
            onDeleteProcedure={async (procedure) => {
              await deleteProcedureAndNotify(
                procedure,
                store.claims,
                queryClient,
                updateStore,
                updateSnackBar,
              );
            }}
            supportedPayers={supportedPayers}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        }
      />
    </Routes>
  );
}
