import "./LeftNav.css";

import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import { AppBar, CssBaseline, Drawer, styled, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";

import { USE_AUTH } from "../../api/apiConfig";
import { usePrefersReducedMotion } from "../../utils/usePrefersReducedMotion";
import { DashboardList } from "./components/DashboardList";
import { GlobalFilter } from "./components/GlobalFilter";
import { LeftPaneLogo } from "./components/LeftPaneLogo";
import { Logout } from "./components/Logout";

const drawerWidth = 17;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}rem`,
  ...(open && {
    width: "calc(100% - 288px)",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface NavProps {
  practices: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  selectedLocations: PracticeMessage[];
  selectPractices: (practices: PracticeMessage[]) => void;
  selectLocations: (locations: PracticeMessage[]) => void;
  children: React.ReactNode;
}

export default function LeftNav({
  practices,
  selectedPractices,
  selectPractices,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectedLocations,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectLocations,
  children,
}: NavProps) {
  const [isNavOpen, setNavOpen] = useState<boolean>(true);
  const prefersReducedMotion = usePrefersReducedMotion();

  const onClickNav = () => {
    setNavOpen(!isNavOpen);
  };

  const handlePracticeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const practiceIdValues =
      typeof value === "string" ? value.split(",") : value;

    const updatedPractices = practiceIdValues.reduce((acc, wieldyId) => {
      const match = practices.find(
        (practice) => practice.wieldyId === wieldyId,
      );
      if (match) {
        acc.push(match);
      }
      return acc;
    }, [] as PracticeMessage[]);

    selectPractices(updatedPractices);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLocationChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const locationIdValues =
      typeof value === "string" ? value.split(",") : value;

    const updatedLocations = locationIdValues.reduce((acc, wieldyId) => {
      const match = selectedLocations.find(
        (location) => location.wieldyId === wieldyId,
      );
      if (match) {
        acc.push(match);
      }
      return acc;
    }, [] as PracticeMessage[]);

    selectLocations(updatedLocations);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" color="inherit" style={{}} elevation={0}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onClickNav}
            edge="start"
            sx={{
              marginTop: "10px",
              transform: "rotate(180deg)",
              mr: 2,
              ...(isNavOpen && { display: "none" }),
            }}
          >
            <FirstPageOutlinedIcon
              sx={{
                border: "solid 2px black",
                borderRadius: "6px",
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: "288px",
          padding: 0,
          margin: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "288px",
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#25233F",
            borderRadius: "0px 24px 24px 0px",
            padding: "10px 15px 10px 15px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isNavOpen}
      >
        <IconButton
          aria-label="Close Pane"
          onClick={onClickNav}
          sx={{ width: "fit-content", marginLeft: "auto", paddingBottom: 0 }}
        >
          <FirstPageOutlinedIcon
            sx={{
              border: "solid 2px white",
              color: "white",
              borderRadius: "6px",
              transform: !isNavOpen
                ? "rotate(180deg)"
                : "transform: rotate(0deg)",
              transition: prefersReducedMotion
                ? ""
                : "transform 0.5s ease-in-out",
            }}
          />
        </IconButton>
        <LeftPaneLogo />
        <GlobalFilter
          selectValues={selectedPractices}
          values={practices}
          label="Practice"
          onChange={handlePracticeChange}
        />
        <Box
          component="hr"
          sx={{
            width: "90%",
            height: "1px",
            backgroundColor: "#E9D7D9",
            border: "none",
            margin: ".25rem 0 2rem 3px",
          }}
        />
        <div className="navigation">
          <nav>
            <DashboardList practices={practices} />
          </nav>
        </div>
        {USE_AUTH && <Logout />}
      </Drawer>
      <Main open={isNavOpen}>{children}</Main>
    </Box>
  );
}
