import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";
import { useForm } from "react-hook-form";

import { formatMoney } from "../../formatters";
import useDevMode from "../../utils/devmode";
import { isoToDate } from "../../utils/utils";
import Loading from "../Loading";
import { renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import AddToolbar from "./AddToolbar";
import {
  AddClaimFormFields,
  defaultClaimFields,
  defaultProcedureFields,
  editableProcedureFields,
  ProcedureFormFields,
  procedureFormSchemaBase,
} from "./CrudForms.zod";
import ProcedureForm from "./ProcedureForm";

const columns: GridColDef[] = [
  {
    flex: 0.7,
    field: "procedureCode",
    headerName: "Code",
  },
  {
    flex: 0.7,
    field: "procedurePayerSubstitutedCode",
    headerName: "Substituted Code",
  },
  {
    flex: 0.7,
    field: "procedureToothNumber",
    headerName: "Tooth Number",
  },
  {
    flex: 1,
    type: "date",
    field: "procedureDate",
    headerName: "Date of Service",
    valueGetter: (params: GridRenderCellParams) =>
      params.row.procedureDate ? isoToDate(params.row.procedureDate) : "",
    renderCell: renderCellRedacted,
  },
  {
    flex: 0.7,
    type: "number",
    field: "procedureSubmittedAmount",
    headerName: "Submitted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedureContractedAmount",
    headerName: "Contracted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    field: "procedureDenialCode",
    headerName: "Denial Reason",
    renderCell: (params: GridRenderCellParams) => {
      const iconPath = `/static/images/info-icon-blue.svg`;
      return params.row.procedureDenialDescription ? (
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {params.row.procedureDenialDescription.replaceAll(" | ", "\n\n")}
            </div>
          }
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <div style={{ marginLeft: "16px", marginTop: "4px" }}>
            <img src={iconPath} alt="Details" width="20" height="20" />
          </div>
        </Tooltip>
      ) : (
        <div />
      );
    },
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePatientResponsibility",
    headerName: "Patient Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePayerPays",
    headerName: "Payer Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
];

const getColumns = (
  editProcedure: (procedure: ProcedureMessage) => void,
  deleteProcedure: (procedure: ProcedureMessage) => void,
  devMode: boolean,
) => {
  const allColumns = [...columns];
  if (devMode) {
    allColumns.push({
      flex: 1,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton
            onClick={() => {
              editProcedure(params.row);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              deleteProcedure(params.row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    });
  }
  return allColumns;
};

interface ProceduresTableProps {
  procedures: ProcedureMessage[];
  isLoading: boolean;
  onSaveProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  onDeleteProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  practices: PracticeMessage[];
}

export default function ProceduresTable({
  procedures,
  isLoading,
  onSaveProcedure,
  onDeleteProcedure,
  practices,
}: ProceduresTableProps) {
  const [openProcedureModal, setOpenProcedureModal] = React.useState(false);
  const [procedureToDelete, setProcedureToDelete] =
    React.useState<ProcedureMessage | null>(null);
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm<ProcedureFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1000,
    resolver: zodResolver(procedureFormSchemaBase),
    defaultValues: defaultProcedureFields,
  });

  const devMode = useDevMode();

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  const onProcedureModal = (open: boolean) => {
    if (open) {
      reset(defaultProcedureFields);
    }
    setOpenProcedureModal(open);
  };

  const onEditProcedure = (procedure: ProcedureMessage) => {
    const formFields = editableProcedureFields.reduce<AddClaimFormFields>(
      (acc, field) => ({
        ...acc,
        [field.name]:
          procedure[field.name as keyof typeof procedure] ??
          defaultClaimFields[field.name as keyof typeof defaultClaimFields],
      }),
      {} as AddClaimFormFields,
    );
    reset(formFields);
    setOpenProcedureModal(true);
  };

  const onOpenDeleteModal = (procedure: ProcedureMessage) => {
    setProcedureToDelete(procedure);
  };

  const onCloseDeleteModal = () => {
    setProcedureToDelete(null);
  };

  const onConfirmDeleteProcedures = async () => {
    if (procedureToDelete && onDeleteProcedure) {
      await onDeleteProcedure(procedureToDelete);
    }
    setProcedureToDelete(null);
  };

  const onProcedureFormSubmission = handleSubmit(async (data) => {
    if (onSaveProcedure) {
      await onSaveProcedure(data as ProcedureMessage);
    }
  });

  if (isLoading) {
    return <Loading />;
  }
  const hasErrors = Object.keys(errors).length > 0;
  const procedureIsDisabled = isSubmitting || hasErrors || !isValid;

  return (
    <>
      <Dialog
        open={!!procedureToDelete}
        onClose={onCloseDeleteModal}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            padding: "24px 36px",
            margin: "auto",
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle sx={{ marginBottom: 2, p: 0 }} id="delete-procedure-title">
          Delete Procedure
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCloseDeleteModal}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: 0, margin: 0 }}>
          <Typography
            paragraph
            sx={{
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#475467",
              marginBottom: 0,
            }}
          >
            Are you sure you want to delete the procedure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: "8px" }}
            color="error"
            variant="contained"
            onClick={onConfirmDeleteProcedures}
            data-testid="submitCredentials"
          >
            Delete procedure
          </Button>
        </DialogActions>
      </Dialog>
      <DataGridPro
        rowHeight={33}
        getRowSpacing={getRowSpacing}
        sx={{
          border: 0,
          fontSize: "0.8rem",
          weight: "500",
          lineHeight: "20px",
          color: "#475467",
          [`&>.${gridClasses.main}`]: {
            [`&>.${gridClasses.columnHeaders}`]: {
              borderBottom: "none",
              backgroundColor: "#F9FAFB",
              fontSize: "0.9rem",
              color: "#303030",
            },
          },
        }}
        rows={procedures.map((procedure, key) => ({ ...procedure, key }))}
        columns={getColumns(onEditProcedure, onOpenDeleteModal, devMode)}
        getRowId={(row) => JSON.stringify(row)}
        disableRowSelectionOnClick
        disableVirtualization
        initialState={{
          sorting: {
            sortModel: [{ field: "procedureDate", sort: "desc" }],
          },
        }}
        slots={{
          toolbar: AddToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            addEntityForm: (
              <ProcedureForm control={control} practices={practices} />
            ),
            handleSubmit: onProcedureFormSubmission,
            isDisabled: procedureIsDisabled,
            entityName: "Procedure",
            enableAddToolBar: true,
            setOpenModal: onProcedureModal,
            openModal: openProcedureModal,
            enableGridToolbar: false,
          },
        }}
      />
    </>
  );
}
