import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { enumToStrings } from "../../utils/utils";
import { BankTransactionType } from "../bankMatch";
import { LineBreak } from "../CredentialsDashboard/ui/LineBreak";

interface TransactionConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  transactions: BankTransactionMessage[];
  onConfirm: (transactions: BankTransactionMessage[]) => Promise<void>;
}

const styles = {
  dialog: { padding: 2, margin: 0 },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem 0",
  },
  header: {
    wrapper: { display: "flex", flexDirection: "column", gap: ".5rem" },
    titleRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
    titleBox: { display: "flex", alignItems: "center", gap: 1 },
    title: { fontSize: "1.125rem", fontWeight: 600, color: "#303030" },
    subtitle: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#475467",
      marginBottom: 1,
    },
    closeButton: { color: "#475467" },
  },
  stats: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      bgcolor: "#F9FAFB",
      p: 2,
      borderRadius: 1,
    },
    warning: {
      display: "flex",
      alignItems: "center",
      gap: 1,
      color: "error.main",
    },
  },
  table: {
    container: { my: 1 },
    headerRow: { backgroundColor: "#F9FAFB" },
    select: { minWidth: 150 },
  },
  actionButtons: {
    container: {
      display: "flex",
      flexDirection: "row",
      marginLeft: "auto",
      gap: "12px",
      mt: 2,
    },
    cancel: {
      backgroundColor: "#EAECF0",
      color: "#475467",
      borderRadius: "8px",
      "&:hover": { backgroundColor: "#e6e7ea" },
    },
    confirm: { borderRadius: "8px" },
    loadingBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
    },
  },
};

const formatCurrency = (amount: number | null): string => {
  if (amount === null) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const formatDate = (dateString: string): string =>
  new Date(dateString).toLocaleDateString();

const transactionTypeCounts = (transactions: BankTransactionMessage[]) =>
  transactions.reduce(
    (acc, transaction) => {
      if (transaction.transactionType === "INSURANCE") {
        acc.insurance += 1;
      } else if (transaction.transactionType === "OTHER") {
        acc.other += 1;
      } else if (transaction.transactionType === null) {
        acc.unknown += 1;
      }
      return acc;
    },
    { insurance: 0, other: 0, unknown: 0 },
  );

const StatsSummary = React.memo(
  ({ transactions }: { transactions: BankTransactionMessage[] }) => {
    const counts = transactionTypeCounts(transactions);
    const stats = [
      {
        label: "Total Transactions",
        value: transactions.length,
        labelColor: "textSecondary",
        valueColor: "inherit",
      },
      {
        label: "Insurance",
        value: counts.insurance,
        labelColor: "textSecondary",
        valueColor: "primary",
      },
      {
        label: "Other",
        value: counts.other,
        labelColor: "textSecondary",
        valueColor: "inherit",
      },
      {
        label: "Needs Review",
        value: counts.unknown,
        labelColor: "error",
        valueColor: "error",
      },
    ];

    return (
      <>
        <Box sx={styles.stats.container}>
          {stats.map((stat) => (
            <Box key={stat.label}>
              <Typography variant="body2" color={stat.labelColor}>
                {stat.label}
              </Typography>
              <Typography variant="h6" color={stat.valueColor}>
                {stat.value}
              </Typography>
            </Box>
          ))}
        </Box>

        {counts.unknown > 0 && (
          <Box sx={styles.stats.warning}>
            <WarningAmberIcon fontSize="small" />
            <Typography variant="body2" color="error">
              Please assign a type to all transactions before confirming.
            </Typography>
          </Box>
        )}
      </>
    );
  },
);

const ModalHeader = React.memo(({ onClose }: { onClose: () => void }) => (
  <Box sx={styles.header.wrapper}>
    <Box sx={styles.header.titleRow}>
      <Box sx={styles.header.titleBox}>
        <InfoOutlinedIcon />
        <Typography sx={styles.header.title}>
          Confirm Bank Transactions
        </Typography>
      </Box>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={styles.header.closeButton}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Typography sx={styles.header.subtitle}>
      Please review and confirm the transaction types before reconciling
    </Typography>
  </Box>
));

const TransactionRow = React.memo(
  ({
    transaction,
    actualIndex,
    onTypeChange,
  }: {
    transaction: BankTransactionMessage;
    actualIndex: number;
    onTypeChange: (e: SelectChangeEvent, index: number) => void;
  }) => {
    const needsType = transaction.transactionType === null;

    return (
      <TableRow key={`transaction-row-${actualIndex}`}>
        <TableCell>{formatDate(transaction.bankDate)}</TableCell>
        <TableCell>{transaction.description}</TableCell>
        <TableCell align="right">
          {formatCurrency(transaction.amount)}
        </TableCell>
        <TableCell>
          <FormControl fullWidth size="small">
            <Select
              value={transaction.transactionType}
              onChange={(e) => onTypeChange(e, actualIndex)}
              sx={styles.table.select}
              displayEmpty
            >
              {enumToStrings(BankTransactionType).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          {needsType ? (
            <Chip
              icon={<WarningAmberIcon />}
              label="Needs Review"
              size="small"
              color="warning"
            />
          ) : (
            <Chip
              icon={<CheckCircleOutlineIcon />}
              label="Ready"
              size="small"
              color="success"
            />
          )}
        </TableCell>
      </TableRow>
    );
  },
);

const ActionButtons = React.memo(
  ({
    onClose,
    onConfirm,
    isSubmitting,
    isDisabled,
  }: {
    onClose: () => void;
    onConfirm: () => Promise<void>;
    isSubmitting: boolean;
    isDisabled: boolean;
  }) => (
    <Box sx={styles.actionButtons.container}>
      <Button
        sx={styles.actionButtons.cancel}
        onClick={onClose}
        type="button"
        variant="contained"
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        sx={styles.actionButtons.confirm}
        color="primary"
        variant="contained"
        onClick={onConfirm}
        disabled={isDisabled || isSubmitting}
      >
        {isSubmitting ? (
          <Box sx={styles.actionButtons.loadingBox}>
            <CircularProgress color="inherit" size={20} />
            Submitting...
          </Box>
        ) : (
          "Confirm & Reconcile"
        )}
      </Button>
    </Box>
  ),
);

export default function TransactionConfirmationModal({
  open,
  onClose,
  transactions,
  onConfirm,
}: TransactionConfirmationModalProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editedTransactions, setEditedTransactions] = useState<
    BankTransactionMessage[]
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setEditedTransactions([...transactions]);
    } else {
      setEditedTransactions([]);
    }
  }, [transactions]);

  const counts = useMemo(
    () => transactionTypeCounts(editedTransactions),
    [editedTransactions],
  );

  const handleTypeChange = useCallback(
    (e: SelectChangeEvent, index: number) => {
      setEditedTransactions((prev) => {
        const updated = [...prev];
        updated[index] = {
          ...updated[index],
          transactionType: e.target.value as keyof typeof BankTransactionType,
        };
        return updated;
      });
    },
    [],
  );

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPage(newPage);

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm(editedTransactions);
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error confirming transactions:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const visibleTransactions = useMemo(
    () =>
      editedTransactions.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [editedTransactions, page, rowsPerPage],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent sx={styles.dialog}>
        <Box sx={styles.container}>
          <ModalHeader onClose={onClose} />
          <LineBreak />
          <StatsSummary transactions={editedTransactions} />

          <TableContainer component={Paper} sx={styles.table.container}>
            <Table sx={{ minWidth: 650 }} size="medium">
              <TableHead>
                <TableRow sx={styles.table.headerRow}>
                  <TableCell>Date</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleTransactions.map((transaction, idx) => {
                  const actualIndex = page * rowsPerPage + idx;
                  return (
                    <TransactionRow
                      key={`transaction-${actualIndex}`}
                      transaction={transaction}
                      actualIndex={actualIndex}
                      onTypeChange={handleTypeChange}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 40]}
              component="div"
              count={editedTransactions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableContainer>

          <ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            isDisabled={counts.unknown > 0}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
