import React from "react";
import { Route, Routes } from "react-router-dom";

import QueryClient from "../../api/query/queryClient";
import MetabaseView from "./MetabaseView";

interface ReportsDashboardProps {
  queryClient: QueryClient;
}

export default function ReportsDashboard({
  queryClient,
}: ReportsDashboardProps) {
  return (
    <Routes>
      <Route path="/" element={<MetabaseView queryClient={queryClient} />} />
    </Routes>
  );
}
