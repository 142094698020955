import { AlertColor } from "@mui/material/Alert/Alert";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import useDevMode from "../../utils/devmode";
import { useClaimsPolling } from "../claimPolling";
import {
  PaymentPlanStore,
  PaymentPlanStoreAction,
} from "../PaymentPlans/store";
import { needsPolling, postPMSUpdateAndNotify } from "../posting";
import {
  initialPatientStore,
  PatientsStore,
  PatientsStoreAction,
  patientsStoreReducer,
} from "./store";
import PatientProfileView from "./views/PatientProfileView";
import PatientsListView from "./views/PatientsListView";
import NewPatientModal from "./views/PatientsListView/NewPatientModal";

interface PatientsDashboardProps {
  store: PatientsStore;
  paymentPlanStore: PaymentPlanStore;
  updateStore: Dispatch<PatientsStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  practices: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  updatePaymentPlanStore: Dispatch<PaymentPlanStoreAction>;
}

export { initialPatientStore, patientsStoreReducer };

export default function PatientsDashboard({
  store,
  paymentPlanStore,
  updateStore,
  updatePaymentPlanStore,
  queryClient,
  updateSnackBar,
  practices,
  selectedPractices,
}: PatientsDashboardProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [triggerReload, setTriggerReload] = useState(false);
  const devMode = useDevMode();
  const isLoadingRef = useRef(false);
  const setIsPolling = useClaimsPolling({
    storeClaims: store.claims,
    queryClient,
    updateStore,
    updateSnackBar,
  });

  /**
   * Immediate redirection are jarring and prevent a user from
   * receiving success or failure feedback.
   */
  useEffect(() => {
    const delayInMilliseconds = 750;
    let timeoutId: ReturnType<typeof window.setTimeout>;

    if (triggerReload) {
      timeoutId = setTimeout(() => {
        const currentPage = 0;
        navigate(currentPage);
      }, delayInMilliseconds);
    }

    return () => clearTimeout(timeoutId);
  }, [triggerReload]);

  const createPatient = async (
    patient: PatientMessage,
    paymentMethodToken?: string,
  ) => {
    try {
      const { wieldyId, ...createPatientMessage } = patient;
      const wieldyPatientId =
        await queryClient.postPatient(createPatientMessage);
      if (paymentMethodToken) {
        await queryClient.postPatientPayment(
          wieldyPatientId,
          paymentMethodToken,
        );
      }
      updateSnackBar({
        severity: "success",
        message: "Successfully created patient",
      });
      const redirect = searchParams.get("redirect");
      if (redirect) {
        searchParams.delete("redirect");
        const to = { pathname: redirect, search: searchParams.toString() };
        navigate(to);
        return;
      }
      navigate({
        pathname: `/patients/${wieldyPatientId}`,
        search: searchParams.toString(),
      });
    } catch (_error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to create patient. Please try again.",
      });
    }
  };

  const editPatient = async (patient: PatientMessage) => {
    // Store original claims for potential rollback
    const originalClaims = store.claims;

    try {
      await queryClient.updatePatient(patient);

      // Optimistically update claims with new patient DOB
      const updatedClaims = store.claims.map((claim) => {
        if (claim.wieldyPatientId === patient.wieldyId) {
          return {
            ...claim,
            patientDob: patient.dob,
            Patient: claim.Patient.map((p) =>
              p.wieldyId === patient.wieldyId ? { ...p, dob: patient.dob } : p,
            ),
          };
        }
        return claim;
      });
      updateStore({
        type: "SET_CLAIMS",
        claims: updatedClaims,
      });

      updateSnackBar({
        severity: "success",
        message: "Successfully edited patient",
      });
      const redirect = searchParams.get("redirect");
      if (redirect) {
        searchParams.delete("redirect");
        const to = { pathname: redirect, search: searchParams.toString() };
        navigate(to);
        return;
      }

      setTriggerReload(true);
    } catch (_error) {
      // Rollback to original state on error
      updateStore({
        type: "SET_CLAIMS",
        claims: originalClaims,
      });

      updateSnackBar({
        severity: "error",
        message: "Failed to edit patient. Please try again later",
      });
    }
  };

  const loadPatients = async (signal?: AbortSignal) => {
    if (isLoadingRef.current || signal?.aborted) {
      return;
    }
    try {
      isLoadingRef.current = true;

      const loadingController = new AbortController();
      if (signal) {
        signal.addEventListener("abort", () => loadingController.abort());
      }

      const loadAllPages = async () => {
        const patientsResponse = await queryClient.getPatients(
          1,
          loadingController.signal,
        );

        if (loadingController.signal.aborted) {
          return;
        }

        updateStore({
          type: "SET_PATIENTS",
          patients: patientsResponse.data,
        });

        const loadNextPage = async (
          currentPage: number,
          totalPages: number,
        ) => {
          if (currentPage > totalPages || loadingController.signal.aborted) {
            return;
          }

          const nextPage = await queryClient.getPatients(
            currentPage,
            loadingController.signal,
          );

          if (loadingController.signal.aborted) {
            return;
          }

          updateStore({
            type: "APPEND_PATIENTS",
            patients: nextPage.data,
          });

          await loadNextPage(currentPage + 1, totalPages);
        };

        if (
          !loadingController.signal.aborted &&
          patientsResponse.total_pages > 1
        ) {
          await loadNextPage(2, patientsResponse.total_pages);
        }
      };

      await loadAllPages();
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        (error.name === "AbortError" || error.name === "CanceledError")
      ) {
        return;
      }
      throw error;
    } finally {
      isLoadingRef.current = false;
    }
  };

  const resetPatients = () => {
    updateStore({
      type: "RESET_PATIENTS",
    });
  };

  const loadPatientAndClaims = async (patientId: string) => {
    const patientClaims = await queryClient.getPatientClaims(patientId);
    updateStore({ type: "SET_PATIENT", patient: patientClaims });
    const claimIds = (patientClaims?.Claim ?? []).map((c) => c.wieldyId);
    const claims = claimIds.length
      ? (await queryClient.getClaimsProceduresAndPatient(claimIds)).data
      : [];
    updateStore({
      type: "SET_CLAIMS",
      claims,
    });
    if (claims.some(needsPolling)) {
      setIsPolling(true);
    }
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    await postPMSUpdateAndNotify(
      claim,
      updateStore,
      queryClient,
      updateSnackBar,
      setIsPolling,
    );
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.updateClaim(claimWork);
  };

  const resetPatientAndClaims = () => {
    updateStore({
      type: "RESET_PATIENT",
    });
    updateStore({
      type: "RESET_CLAIMS",
    });
  };

  const loadPatientPaymentPlans = async (
    patientId: PatientMessage["wieldyId"],
  ) => {
    if (devMode && patientId) {
      try {
        const payments = await queryClient.getPatientPaymentPlans(patientId);
        if (payments) {
          updatePaymentPlanStore({
            type: "SET_LOADED_PAYMENT_PLANS",
            payload: payments,
          });
        }
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to load patient payment methods",
        });
      }
    }
  };

  const clearPatientPaymentPlan = async (
    paymentPlanId: PaymentPlanMessage["id"],
  ) => {
    if (devMode && paymentPlanId) {
      try {
        await queryClient.cancelPatientPaymentPlans(paymentPlanId);

        updatePaymentPlanStore({
          type: "CLEAR_PAYMENT_PLAN",
          payload: paymentPlanId,
        });
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to cancel patient payment plan",
        });
      }
    }
  };

  const loadPatientPayments = async (patientId: PatientMessage["wieldyId"]) => {
    if (devMode && patientId) {
      try {
        const payments = await queryClient.getPatientPayments(patientId);
        updatePaymentPlanStore({
          type: "SET_CURRENT_PAYMENTS_OPTIONS",
          payload: payments ?? [],
        });

        if (payments && payments.length) {
          const primaryPaymentMethod = payments.find(
            (payment) => payment.primary,
          );
          if (primaryPaymentMethod) {
            updatePaymentPlanStore({
              type: "SET_PAYMENT_METHOD",
              payload: primaryPaymentMethod,
            });
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to load patient payment methods",
        });
      }
    }
  };

  useEffect(
    () => () => {
      isLoadingRef.current = false;
    },
    [],
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PatientsListView
            patients={store.patients}
            practices={practices}
            selectedPractices={selectedPractices}
            patientsLoading={!store.loadedPatients}
            load={loadPatients}
            reset={resetPatients}
            enablePagination
          />
        }
      />
      <Route
        path="/:patientId"
        element={
          <PatientProfileView
            patient={store.patient}
            patientLoading={!store.loadedPatient}
            claims={store.claims}
            claimsLoading={!store.loadedClaims}
            load={loadPatientAndClaims}
            loadPatientPaymentPlans={loadPatientPaymentPlans}
            paymentPlans={paymentPlanStore.loadedPaymentPlans}
            paymentPlanStore={paymentPlanStore}
            loadPatientPayments={loadPatientPayments}
            update={updateClaim}
            editPatient={editPatient}
            reset={resetPatientAndClaims}
            toPMS={postToPMS}
            updateSnackBar={updateSnackBar}
            practices={practices}
            clearPatientPaymentPlan={clearPatientPaymentPlan}
          />
        }
      />
      <Route
        path="/new-patient"
        element={
          <NewPatientModal
            updateSnackBar={updateSnackBar}
            onSubmit={createPatient}
            practices={practices}
            onClose={() => {
              navigate(-1);
            }}
            open
          />
        }
      />
    </Routes>
  );
}
