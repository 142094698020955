import { replaceByWieldyId } from "../../utils/utils";

export type PatientsStore = {
  patients: PatientWithClaimMessage[];
  loadedPatients: boolean;
  isLoadingMore: boolean;
  hasMorePages: boolean;
  currentPage: number;
  patient?: PatientWithClaimMessage;
  loadedPatient: boolean;
  claims: ClaimWithProcedureAndPatientMessage[];
  loadedClaims: boolean;
  error?: Error;
};

export const initialPatientStore: PatientsStore = {
  patients: [],
  loadedPatients: false,
  isLoadingMore: false,
  hasMorePages: true,
  currentPage: 1,
  patient: undefined,
  loadedPatient: false,
  claims: [],
  loadedClaims: false,
  error: undefined,
};

export type PatientsStoreAction =
  | { type: "SET_PATIENTS"; patients: PatientWithClaimMessage[] }
  | { type: "APPEND_PATIENTS"; patients: PatientWithClaimMessage[] }
  | { type: "RESET_PATIENTS" }
  | { type: "SET_PATIENT"; patient?: PatientWithClaimMessage }
  | { type: "UPDATE_PATIENT"; patient: PatientWithClaimMessage }
  | { type: "RESET_PATIENT" }
  | { type: "SET_CLAIMS"; claims: ClaimWithProcedureAndPatientMessage[] }
  | { type: "UPDATE_CLAIM"; claimToUpdate: ClaimWithProcedureAndPatientMessage }
  | { type: "RESET_CLAIMS" }
  | { type: "SET_LOADING_MORE"; isLoading: boolean }
  | { type: "SET_HAS_MORE_PAGES"; hasMore: boolean }
  | { type: "SET_CURRENT_PAGE"; page: number }
  | { type: "SET_ERROR"; error: Error };

export function patientsStoreReducer(
  store: PatientsStore,
  action: PatientsStoreAction,
): PatientsStore {
  switch (action.type) {
    case "SET_PATIENTS":
      return {
        ...store,
        patients: action.patients,
        loadedPatients: true,
        error: undefined,
      };

    case "APPEND_PATIENTS":
      return {
        ...store,
        patients: [...store.patients, ...action.patients],
        loadedPatients: true,
        error: undefined,
      };

    case "RESET_PATIENTS":
      return {
        ...store,
        patients: [],
        loadedPatients: false,
        isLoadingMore: false,
        hasMorePages: true,
        currentPage: 1,
        error: undefined,
      };

    case "SET_PATIENT":
      return {
        ...store,
        patient: action.patient,
        loadedPatient: true,
        error: undefined,
      };

    case "UPDATE_PATIENT":
      return {
        ...store,
        patient: {
          ...store.patient,
          ...action.patient,
        },
        error: undefined,
      };

    case "RESET_PATIENT":
      return {
        ...store,
        patient: undefined,
        loadedPatient: false,
        error: undefined,
      };

    case "SET_CLAIMS":
      return {
        ...store,
        claims: action.claims,
        loadedClaims: true,
        error: undefined,
      };

    case "UPDATE_CLAIM":
      return {
        ...store,
        claims: replaceByWieldyId(store.claims, action.claimToUpdate) ?? [
          action.claimToUpdate,
        ],
        error: undefined,
      };

    case "RESET_CLAIMS":
      return {
        ...store,
        claims: [],
        loadedClaims: false,
        error: undefined,
      };

    case "SET_LOADING_MORE":
      return {
        ...store,
        isLoadingMore: action.isLoading,
      };

    case "SET_HAS_MORE_PAGES":
      return {
        ...store,
        hasMorePages: action.hasMore,
      };

    case "SET_CURRENT_PAGE":
      return {
        ...store,
        currentPage: action.page,
      };

    case "SET_ERROR":
      return {
        ...store,
        error: action.error,
      };

    default:
      return store;
  }
}
