import { type Axios, AxiosError } from "axios";

import { type AddPayerCredentialsFormFields } from "../../components/CredentialsDashboard/Form/PayerCredentialsForm.zod";
import { PostedState } from "../../components/posting";
import { ClaimStatus } from "../../status";
import { stringifyValues } from "../../utils/utils";
import { Backend, getBackend } from "../apiConfig";

class QueryClient {
  private api: { getClient: () => Promise<Axios> };

  private devMode: boolean;

  constructor(devMode: boolean, api: { getClient: () => Promise<Axios> }) {
    this.devMode = devMode;
    this.api = api;
  }

  reviewStatusFilter(): string[] | undefined {
    if (!this.devMode) {
      return ["ACCEPTED", "BYPASS"];
    }
    // We hide more claims in devmode for production to reduce the noise for
    // the operations team when reviewing claims.
    if (this.devMode && getBackend() === Backend.Production) {
      return ["ACCEPTED", "BYPASS", "REVIEW", "MODIFIED"];
    }
    return undefined;
  }

  async getPatients(page = 1, signal?: AbortSignal): Promise<SearchResponse> {
    const client = await this.api.getClient();
    const response = await client.post<SearchResponse>(
      "/patients/search",
      {
        filters: {},
        page,
        page_size: 500,
        sort: [],
        sort_direction: "desc",
      },
      { signal },
    );
    return response.data;
  }

  async getPatientClaims(
    patientId: string,
  ): Promise<PatientWithClaimMessage | undefined> {
    const client = await this.api.getClient();
    const response = await client.get<PatientWithClaimMessage>(
      `/patients/${patientId}`,
    );
    return response.data;
  }

  async getPatientTransactions(
    patientId: string,
  ): Promise<PaymentTransactionMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<{ items: PaymentTransactionMessage[] }>(
      `/patient/${patientId}/transactions`,
    );
    return response.data?.items ?? [];
  }

  async getPatientPaymentPlans(
    patientId: string,
  ): Promise<PaymentPlanMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<{ paymentPlans: PaymentPlanMessage[] }>(
      `/patient/${patientId}/payment-plans`,
    );
    return response.data.paymentPlans;
  }

  async getPatientPaymentPlan(paymentPlanId: string): Promise<Blob> {
    const client = await this.api.getClient();

    const response = await client.get<Blob>(`/payment-plans/${paymentPlanId}`, {
      responseType: "blob",
      headers: {
        accept: "application/pdf",
      },
    });
    return response.data;
  }

  async cancelPatientPaymentPlans(paymentPlanId: string): Promise<void> {
    const client = await this.api.getClient();

    await client.post(`/payment-plans/${paymentPlanId}/cancellation`);
  }

  async sentPatientPaymentPlanViaSms(paymentPlanId: string): Promise<void> {
    const client = await this.api.getClient();

    await client.post(`/payment-plans/${paymentPlanId}/notify/sms`);
  }

  // /payment-plans/{payment_plan_id}/installments/{installment_id}/attempts
  async manuallyReattemptPayment(
    paymentPlanId: string,
    installmentId: string,
  ): Promise<void> {
    const client = await this.api.getClient();

    await client.post(
      `/payment-plans/${paymentPlanId}/installments/${installmentId}/attempts`,
    );
  }

  async getPatientPayments(
    patientId: string,
  ): Promise<PatientPaymentMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<PatientPaymentMessage[]>(
      `patient/${patientId}/payment-methods`,
    );
    return response.data;
  }

  async getPublicPatientPayments(
    hash: string,
  ): Promise<PatientPaymentMessage[] | undefined> {
    const client = await this.api.getClient();
    const response = await client.get<PatientPaymentMessage[]>(
      `public-patient/${hash}/payment-methods`,
    );
    return response.data;
  }

  async postPatientPayment(
    patientId: string,
    token: string,
  ): Promise<PatientPaymentMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PatientPaymentMessage>(
      `patient/${patientId}/payment-methods`,
      {
        token,
      },
    );
    return response.data;
  }

  async postPublicPatientPayment(
    hash: string,
    token: string,
  ): Promise<PatientPaymentMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PatientPaymentMessage>(
      `/public-patient/${hash}/payment-methods`,
      {
        token,
      },
    );
    return response.data;
  }

  async postPatientPaymentPlanUnsignedPdf(
    patientId: string,
    paymentPlanMessage: PaymentPlanMessage & { signatureDate: string },
  ): Promise<Blob> {
    const client = await this.api.getClient();
    const response = await client.post<Blob>(
      `patient/${patientId}/unsigned-payment-plan`,
      paymentPlanMessage,
      { responseType: "blob" },
    );
    return response.data;
  }

  async postPatientPaymentMethod(
    patientId: string,
    paymentPlanMessage: PaymentPlanMessage & {
      signatureDate: string;
      signature: string;
    },
  ): Promise<PaymentPlanMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PaymentPlanMessage>(
      `patient/${patientId}/payment-plans`,
      paymentPlanMessage,
    );
    return response.data;
  }

  async setPatientPrimaryPaymentMethod(
    patientId: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.patch<object>(`patient/${patientId}`, {
      primaryPaymentMethodId: paymentMethodId,
    });
    return response.data;
  }

  async setPublicPatientPrimaryPaymentMethod(
    hash: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.patch<object>(`public-patient/${hash}`, {
      primaryPaymentMethodId: paymentMethodId,
    });
    return response.data;
  }

  async deletePaymentMethod(
    patientId: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.delete<object>(
      `patient/${patientId}/payment-methods/${paymentMethodId}`,
    );
    return response.data;
  }

  async deletePublicPaymentMethod(
    hash: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.delete<object>(
      `public-patient/${hash}/payment-methods/${paymentMethodId}`,
    );
    return response.data;
  }

  async getPayments(page = 1): Promise<SearchResponse> {
    const client = await this.api.getClient();
    const filters: {
      payment_type: (string | null)[];
      payment_status: (string | null)[];
      review_status?: string[];
    } = {
      payment_type:
        getBackend() === Backend.Production
          ? [null, "EFT"]
          : [null, "CHECK", "VIRTUAL_CARD", "EFT"],
      payment_status: [null, "Cleared"],
      review_status: this.reviewStatusFilter(),
    };
    const response = await client.post<SearchResponse>("/payments/search", {
      filters,
      page,
      page_size: 250,
      sort: [],
      sort_direction: "desc",
    });
    return response.data;
  }

  async getPaymentDetail(paymentId: string): Promise<PaymentDetailMessage> {
    const client = await this.api.getClient();
    const response = await client.get<PaymentDetailMessage>(
      `/payments/${paymentId}?dev_mode=${this.devMode}`,
    );
    return response.data;
  }

  async getClaim(
    claimId: string,
  ): Promise<ClaimWithProcedureAndPatientMessage> {
    const client = await this.api.getClient();
    const response = await client.get<ClaimWithProcedureAndPatientMessage>(
      `/claims/${claimId}`,
    );
    return response.data;
  }

  async getClaimsProceduresAndPatient(
    claimIds: string[],
    page = 1,
    signal?: AbortSignal,
    additionalFilters?: Record<string, string[]>,
  ): Promise<SearchResponse> {
    const client = await this.api.getClient();
    const filters: {
      claim_type: (string | null)[];
      claim_status: (string | null)[];
      review_status?: string[];
      wieldy_id?: string[];
    } = {
      claim_type: [null, "CLAIM"],
      claim_status: [
        null,
        ClaimStatus.APPROVED,
        ClaimStatus.DENIED,
        ClaimStatus.PARTIALLY_DENIED,
      ],
      review_status: this.reviewStatusFilter(),
      ...additionalFilters,
    };
    if (claimIds.length) {
      filters.wieldy_id = claimIds;
    }
    const response = await client.post<SearchResponse>(
      "/claims/search",
      {
        filters,
        page,
        page_size: 500,
        sort: [],
        sort_direction: "desc",
      },
      { signal },
    );
    return response.data;
  }

  async getBankTransactions(
    page = 1,
    filters: Record<string, (string | null)[]> = {},
    pageSize = 50,
  ): Promise<SearchResponse> {
    const client = await this.api.getClient();
    const response = await client.post<SearchResponse>(
      "/bank-transactions/search",
      {
        filters,
        page,
        page_size: pageSize,
        sort: ["bank_date"],
        sort_direction: "desc",
      },
    );
    return response.data;
  }

  async updateClaim(claimMessage: Partial<ClaimMessage>): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Claim",
      payload: claimMessage,
      verb: "UPDATE",
    });
    return response.data.wieldyId;
  }

  /**
   * Mapped data values need to be stringified before being sent to the backend.
   * Compared to when we update "work" data which can be strings, numbers, booleans, etc.
   * @param claimMessage
   */
  async updateStringifyClaim(
    claimMessage: Partial<ClaimMessage>,
  ): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Claim",
      payload: stringifyValues(claimMessage),
      verb: "UPDATE",
    });
    return response.data.wieldyId;
  }

  async updateProcedure(procedure: Partial<ProcedureMessage>): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Procedure",
      payload: stringifyValues(procedure),
      verb: "UPDATE",
    });
    return response.data.wieldyId;
  }

  async createProcedure(procedure: ProcedureMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Procedure",
      payload: stringifyValues(procedure),
      verb: "CREATE",
    });
    return response.data.wieldyId;
  }

  async deleteProcedure(
    procedure: ProcedureMessage,
    practiceId: string,
  ): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Procedure",
      payload: {
        wieldyId: procedure.wieldyId,
        practiceId,
      },
      verb: "DELETE",
    });
    return response.data.wieldyId;
  }

  async createClaim(claim: ClaimMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Claim",
      // The /updates endpoint doesn't take anything but strings currently.
      payload: stringifyValues(claim),
      verb: "CREATE",
    });
    return response.data.wieldyId;
  }

  async deleteClaim(
    claim: ClaimWithProcedureAndPatientMessage,
  ): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Claim",
      payload: { wieldyId: claim.wieldyId, practiceId: claim.practiceId },
      verb: "DELETE",
    });
    return response.data.wieldyId;
  }

  async postToPMS(
    claimId: string,
    send_notification: () => void,
  ): Promise<ClaimPostingStatus> {
    const { search } = window.location;
    const client = await this.api.getClient();

    const notificationInterval = setInterval(() => {
      send_notification();
    }, 15000);

    try {
      const response = await client.post(`pms/${claimId}${search}`, {
        claimId,
      });
      return response.data;
    } catch (err: unknown) {
      /*
        Right now, posting to some PMS takes over the 60 connection timeout.
        Instead of increasing the timeout period, we'll poll for the status.
       */
      if (err instanceof AxiosError && err.code === "ERR_NETWORK") {
        let attempts = 0;
        // 20* 12 = 4 minutes because Ortho2 posting can take up to 4 minutes
        const maxAttempts = 12;

        /* eslint-disable no-await-in-loop */
        while (attempts < maxAttempts) {
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 20000));
          const claim = await this.getClaim(claimId);
          if (claim.postedState !== PostedState[PostedState.POSTING]) {
            return {
              wieldyId: claimId,
              postedState: claim.postedState,
              postedDateTime: claim.postedDateTime,
              postedAttempts: claim.postedAttempts,
              postedLatestErrorMessage: claim.postedLatestErrorMessage,
              postingCompletedSteps: claim.postingCompletedSteps,
              postingExpectedSteps: claim.postingExpectedSteps,
            };
          }
          attempts += 1;
        }
      }
      throw err;
    } finally {
      clearInterval(notificationInterval);
    }
  }

  async saveFile(
    practiceId: string,
    payer: string,
    file: File,
  ): Promise<FileMessage> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("practice_id", practiceId);
    formData.append("payer", payer);
    const response = await client.post(`files/${search}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  async uploadBankTransactions(
    practiceId: string,
    file: File,
  ): Promise<BankTransactionMessage[]> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const formData = new FormData();
    formData.append("file", file);
    const response = await client.post(
      `/bank-transactions/upload/${practiceId}${search}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  }

  async reconcileBankTransactions(
    practiceId: string,
    transactions: BankTransactionMessage[],
  ): Promise<BankTransactionUploadMessage> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(
      `/bank-transactions/reconcile/${practiceId}${search}`,
      transactions,
    );
    return response.data;
  }

  async updateBankTransaction(
    transactionUpdate: BankTransactionUpdateMessage,
  ): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    await client.patch(
      `/bank-transactions/${transactionUpdate.wieldyId}${search}`,
      transactionUpdate,
    );
  }

  async batchPostToPMS(): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    await client.post(`pms${search}`);
  }

  async postPatient(patient: CreatePatientMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Patient",
      payload: patient,
    });
    return response.data.wieldyId;
  }

  async updatePatient(patient: PatientMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Patient",
      payload: patient,
    });
    return response.data.wieldyId;
  }

  async getPractices(): Promise<PracticeMessage[]> {
    const client = await this.api.getClient();
    const response = await client.get<PracticeMessage[]>("/practices");
    return response.data;
  }

  async getCredentialsSupportedPayers(): Promise<
    CredentialsSupportedPayersMessage[] | undefined
    > {
    const client = await this.api.getClient();

    const response = await client.get<CredentialsSupportedPayersMessage[]>(
      `/credentials/supported-payers`,
    );
    return response.data ?? [];
  }

  async getSupportedSources(): Promise<SourceMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<SourceMessage[]>(`/sources/`);
    return response.data ?? [];
  }

  async getUserCredentials(): Promise<UserCredentialsMessage[] | undefined> {
    const client = await this.api.getClient();
    const response =
      await client.get<UserCredentialsMessage[]>(`/credentials/`);
    return response.data ?? [];
  }

  async oauthEmail(): Promise<AuthEmailMessage> {
    const client = await this.api.getClient();
    const response = await client.get<AuthEmailMessage>(`/access/authorize`);
    return response.data;
  }

  async unmaskAttribute(
    wieldyId: string,
    name: string,
  ): Promise<Partial<UserCredentialsMessage>> {
    const client = await this.api.getClient();
    const response = await client.get(
      `/credentials/payers/${wieldyId}/unmask?name=${name}`,
    );
    return response.data;
  }

  async postCreateCredentials({
    type,
    sourceId,
    practiceId,
    username,
    password,
    website,
    notes,
  }: {
    type: AddPayerCredentialsFormFields["type"];
    sourceId: SourceMessage["id"];
    practiceId: PracticeMessage["wieldyId"];
    username: UserCredentialsMessage["username"];
    password: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    // We don't have access to portalId yet, so we use a placeholder till we
    // have access.
    await client.post(
      `/credentials/sources/${sourceId}/practices/${practiceId}`,
      {
        type,
        username,
        password,
        website,
        notes,
      },
    );
  }

  async postOtherCredentials({
    type,
    practiceId,
    name,
    username,
    password,
    website,
    notes,
  }: {
    type: string;
    practiceId: PracticeMessage["wieldyId"];
    name: string;
    username: UserCredentialsMessage["username"];
    password: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.post(`/credentials/other/practices/${practiceId}`, {
      type,
      name,
      username,
      password,
      website,
      notes,
    });
  }

  async deletePayerCredential(
    credentialId: UserCredentialsMessage["wieldyId"],
  ): Promise<void> {
    const client = await this.api.getClient();
    await client.delete(`/credentials/payers/${credentialId}`);
  }

  async deleteOtherCredential(
    credentialId: UserCredentialsMessage["wieldyId"],
  ): Promise<void> {
    const client = await this.api.getClient();
    await client.delete(`/credentials/other/${credentialId}`);
  }

  async updateCreateCredentials({
    credentialId,
    ...body
  }: {
    credentialId: UserCredentialsMessage["wieldyId"];
    username?: UserCredentialsMessage["username"];
    password?: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.patch(`/credentials/payers/${credentialId}`, body);
  }

  async updateOtherCredentials({
    credentialId,
    ...body
  }: {
    credentialId: UserCredentialsMessage["wieldyId"];
    username?: UserCredentialsMessage["username"];
    password?: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.patch(`/credentials/other/${credentialId}`, body);
  }

  async getScreenshots(claimId: string): Promise<string[]> {
    const client = await this.api.getClient();
    const response = await client.get(`claims/${claimId}/screenshots`);
    return response.data;
  }

  async getTasks(): Promise<Task[]> {
    const client = await this.api.getClient();
    const response = await client.get<Task[]>("tasks/");
    return response.data;
  }

  async completeTask(taskId: string): Promise<Task> {
    const client = await this.api.getClient();
    const response = await client.patch<Task>(`tasks/${taskId}/complete`);
    return response.data;
  }

  async getMetabaseToken(): Promise<MetabaseToken> {
    const client = await this.api.getClient();
    const response = await client.get<MetabaseToken>(`/reports/metabase/sign`);
    return response.data;
  }

  async getOrganization(): Promise<OrganizationMessage> {
    const client = await this.api.getClient();
    const response = await client.get<OrganizationMessage>(`/organizations`);
    return response.data;
  }
}

export default QueryClient;
