import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import DateRangePicker, { DateRange } from "../DateRangePicker";
import PaymentsTable from "../PaymentsTable";

export interface PaymentDashboardSettings {
  selectedDate: DateRange;
}

function filterPayments(
  payments: PaymentMessage[],
  // TODO: Consistently order practices before locations.  Practice, then location, always.
  // TODO: Define a PracticeLocation type composing practice with location.
  _selectedLocations: PracticeMessage["displayName"][],
  selectedPractices: PracticeMessage["displayName"][],
  dateRange: DateRange,
): PaymentMessage[] {
  const selectedPracticeSet = new Set(selectedPractices);
  const paymentsByPractice =
    payments.filter((payment) =>
      selectedPracticeSet.has(payment.practiceDisplayName),
    ) || [];

  return paymentsByPractice.filter((payment) => {
    if (!payment.paymentDate) {
      return false;
    }
    const paymentDate = dayjs(payment.paymentDate);
    return (
      (paymentDate.isSame(dateRange.startDate, "day") ||
        paymentDate.isAfter(dateRange.startDate, "day")) &&
      (paymentDate.isSame(dateRange.endDate, "day") ||
        paymentDate.isBefore(dateRange.endDate, "day"))
    );
  });
}

interface PaymentsListViewProps {
  payments: PaymentMessage[];
  paymentsLoading: boolean;
  load: () => Promise<void>;
  reset: () => void;
  selectedLocations: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  dashboardSettings: PaymentDashboardSettings;
  saveDashboardSettings: (newSettings: PaymentDashboardSettings) => void;
}

export default function PaymentsListView({
  payments,
  paymentsLoading,
  load,
  reset,
  selectedLocations,
  selectedPractices,
  dashboardSettings,
  saveDashboardSettings,
}: PaymentsListViewProps) {
  useEffect(() => {
    load();
    return reset;
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const selectedPracticeNames = selectedPractices.map(
    (practice) => practice.displayName,
  );
  const selectedLocationNames = selectedLocations.map(
    (practice) => practice.displayName,
  );

  const handleRowClick = (payment: PaymentMessage | null) => {
    if (payment) {
      navigate({
        pathname: `/payments/${payment.wieldyId}`,
        search: searchParams.toString(),
      });
    }
  };

  const onDateChange = (dateRange: DateRange) => {
    saveDashboardSettings({ ...dashboardSettings, selectedDate: dateRange });
  };

  const paymentsByDate = payments
    .filter((p) => !!p.paymentDate)
    .sort((a, b) =>
      dayjs(a.paymentDate).isAfter(dayjs(b.paymentDate)) ? -1 : 1,
    );

  return (
    <>
      <h1>Payments</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "14px",
        }}
      >
        <div>
          <DateRangePicker
            selectedDateRange={dashboardSettings.selectedDate}
            setDateRange={onDateChange}
            earliestStartDate={
              paymentsByDate?.length
                ? paymentsByDate[paymentsByDate.length - 1].paymentDate
                : null
            }
          />
        </div>
      </div>
      <PaymentsTable
        isLoading={paymentsLoading}
        payments={filterPayments(
          paymentsByDate,
          selectedLocationNames,
          selectedPracticeNames,
          dashboardSettings.selectedDate,
        )}
        onSelectPayment={handleRowClick}
        persistKey="paymentsListView"
      />
    </>
  );
}
