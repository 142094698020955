import { replaceByWieldyId } from "../../utils/utils";

export type PaymentsStore = {
  payment: PaymentWithClaimMessage | null;
  claims: ClaimWithProcedureAndPatientMessage[];
  payments: PaymentMessage[];
  loadedClaimsProcedures: boolean;
  loadedPayments: boolean;
};

export const initialPaymentsStore: PaymentsStore = {
  payment: null,
  claims: [],
  payments: [],
  loadedClaimsProcedures: false,
  loadedPayments: false,
};

export type PaymentsStoreAction =
  | {
      type: "SET_PAYMENT";
      payment: PaymentWithClaimMessage;
      claims: ClaimWithProcedureAndPatientMessage[];
    }
  | { type: "SET_PAYMENTS"; payments: PaymentMessage[] }
  | { type: "APPEND_PAYMENTS"; payments: PaymentMessage[] }
  | { type: "RESET_PAYMENT" }
  | { type: "RESET_PAYMENTS" }
  | { type: "SET_CLAIMS"; claims: ClaimWithProcedureAndPatientMessage[] }
  | { type: "UPDATE_CLAIM"; claimToUpdate: ClaimWithProcedureAndPatientMessage }
  | { type: "UPDATE_PAYMENT"; paymentToUpdate: PaymentMessage }
  | {
      type: "CREATE_CLAIM";
      createdClaim: ClaimWithProcedureAndPatientMessage;
    }
  | {
      type: "DELETE_CLAIM";
      claimKey: string;
    }
  | {
      type: "UPDATE_PROCEDURE";
      claimKey: string;
      procedure: ProcedureMessage;
    }
  | {
      type: "CREATE_PROCEDURE";
      claimKey: string;
      procedure: ProcedureMessage;
    }
  | {
      type: "DELETE_PROCEDURE";
      claimKey: string;
      procedureKey: string;
    };

export function paymentsStoreReducer(
  store: PaymentsStore,
  action: PaymentsStoreAction,
): PaymentsStore {
  switch (action.type) {
    case "RESET_PAYMENT":
      return {
        ...store,
        payment: null,
        claims: [],
        loadedClaimsProcedures: false,
      };
    case "RESET_PAYMENTS":
      return {
        ...store,
        payments: [],
        loadedPayments: false,
      };
    case "APPEND_PAYMENTS":
      return {
        ...store,
        payments: [...store.payments, ...action.payments],
      };
    case "SET_PAYMENT":
      return {
        ...store,
        payment: action.payment,
        claims: action.claims,
        loadedClaimsProcedures: true,
      };
    case "SET_PAYMENTS":
      return {
        ...store,
        payments: action.payments,
        loadedPayments: true,
      };
    case "SET_CLAIMS":
      return {
        ...store,
        claims: action.claims,
        loadedPayments: true,
      };
    case "UPDATE_CLAIM":
      return {
        ...store,
        claims: replaceByWieldyId(store.claims, action.claimToUpdate) ?? [
          action.claimToUpdate,
        ],
      };
    case "UPDATE_PAYMENT":
      return {
        ...store,
        payments: replaceByWieldyId(store.payments, action.paymentToUpdate) ?? [
          action.paymentToUpdate,
        ],
      };
    case "CREATE_CLAIM":
      return {
        ...store,
        claims: [...store.claims, action.createdClaim],
      };
    case "DELETE_CLAIM":
      return {
        ...store,
        claims: store.claims.filter(
          (claim) => claim.wieldyId !== action.claimKey,
        ),
      };
    case "UPDATE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: claim.Procedure.map((proc) =>
                proc.wieldyId === action.procedure.wieldyId
                  ? action.procedure
                  : proc,
              ),
            };
          }
          return claim;
        }),
      };
    case "CREATE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: [...claim.Procedure, action.procedure],
            };
          }
          return claim;
        }),
      };
    case "DELETE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: claim.Procedure.filter(
                (procedure) => procedure.wieldyId !== action.procedureKey,
              ),
            };
          }
          return claim;
        }),
      };
    default:
      return store;
  }
}
