import { useCallback, useEffect, useState } from "react";

import useQueryClient from "../api/query";

export const FEATURE_FLAG_KEYS = {
  BANK_RECONCILIATION: "bankReconciliation",
  REPORTS: "reports",
} as const;

export type FeatureFlagKey = keyof typeof FEATURE_FLAG_KEYS;
export type FeatureFlagValue = (typeof FEATURE_FLAG_KEYS)[FeatureFlagKey];

type FeatureFlagsState = {
  featureFlags: Record<FeatureFlagValue, boolean>;
  isLoading: boolean;
  error: Error | null;
};

// Singleton for caching the feature flags across components
let featureFlagsCache: FeatureFlagsState | null = null;
let featureFlagsFetchPromise: Promise<FeatureFlagsState> | null = null;

/**
 * Hook to fetch all feature flags at once
 */
export function useFeatureFlags(): FeatureFlagsState {
  const queryClient = useQueryClient();
  const [state, setState] = useState<FeatureFlagsState>(
    () =>
      featureFlagsCache || {
        featureFlags: Object.values(FEATURE_FLAG_KEYS).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {} as Record<FeatureFlagValue, boolean>,
        ),
        isLoading: true,
        error: null,
      },
  );

  const fetchFeatureFlags = useCallback(async () => {
    // If there's already a fetch in progress, return that promise
    if (featureFlagsFetchPromise) {
      return featureFlagsFetchPromise;
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    featureFlagsFetchPromise = queryClient
      .getOrganization()
      .then((organization) => {
        const flags: Record<FeatureFlagValue, boolean> = {
          [FEATURE_FLAG_KEYS.BANK_RECONCILIATION]:
            !!organization.featureFlags?.bankReconciliation,
          [FEATURE_FLAG_KEYS.REPORTS]: !!organization.featureFlags?.reports,
        };

        const newState = {
          featureFlags: flags,
          isLoading: false,
          error: null,
        };

        setState(newState);
        featureFlagsCache = newState;
        return newState;
      })
      .catch((error) => {
        const errorState = {
          featureFlags: Object.values(FEATURE_FLAG_KEYS).reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {} as Record<FeatureFlagValue, boolean>,
          ),
          isLoading: false,
          error,
        };
        // eslint-disable-next-line no-console
        console.error("Failed to fetch feature flags:", error);
        setState(errorState);
        return errorState;
      })
      .finally(() => {
        featureFlagsFetchPromise = null;
      });

    return featureFlagsFetchPromise;
  }, []);

  useEffect(() => {
    fetchFeatureFlags();
  }, [fetchFeatureFlags]);

  return state;
}

/**
 * Hook to get a specific feature flag value
 */
export function useFeatureFlag(featureKey: FeatureFlagValue): {
  isEnabled: boolean;
  isLoading: boolean;
} {
  const { featureFlags, isLoading } = useFeatureFlags();

  return {
    isEnabled: featureFlags[featureKey] || false,
    isLoading,
  };
}
