import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";

import type QueryClient from "../api/query/queryClient";
import { isoNow } from "../utils/utils";

export enum PostedState {
  UNPOSTED,
  FAILURE,
  AUTO_POSTED,
  MANUALLY_POSTED,
  POSTING,
  RETRY,
  PENDING,
}

// Make sure the poll length is greater than the 1s
// worker to queue polling
export const POLL_INTERVAL = 1900;
export const MAX_POLL_TIME = 3600000;

const successStates = [
  PostedState[PostedState.AUTO_POSTED],
  PostedState[PostedState.MANUALLY_POSTED],
];

const inProgressStates = [
  PostedState[PostedState.PENDING],
  PostedState[PostedState.POSTING],
];

const failureStates = [
  PostedState[PostedState.FAILURE],
  PostedState[PostedState.RETRY],
];

export function hasPosted(claim: ClaimWithProcedureMessage): boolean {
  return successStates.includes(claim.postedState);
}

export function hasFailed(claim: ClaimWithProcedureMessage): boolean {
  return failureStates.includes(claim.postedState);
}

export function postingStatusSuccess(
  postingStatus: ClaimPostingStatus,
): boolean {
  return successStates.includes(postingStatus.postedState);
}

export function postingStatusInProgress(
  postingStatus: ClaimPostingStatus,
): boolean {
  return inProgressStates.includes(postingStatus.postedState);
}

export function needsPolling(
  claim: ClaimWithProcedureAndPatientMessage,
): boolean {
  return [
    PostedState[PostedState.POSTING],
    PostedState[PostedState.PENDING],
  ].includes(claim.postedState);
}

export async function postPMSUpdateAndNotify(
  claim: ClaimWithProcedureAndPatientMessage,
  updateStore: (action: {
    type: "UPDATE_CLAIM";
    claimToUpdate: ClaimWithProcedureAndPatientMessage;
  }) => void,
  queryClient: QueryClient,
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void,
  setIsPolling: (isPolling: boolean) => void,
) {
  try {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: {
        ...claim,
        postedState: PostedState[PostedState.POSTING],
        updatedAt: isoNow(),
      },
    });
    updateSnackBar({
      severity: "info",
      message: "Posting to PMS",
    });
    const postResponse = await queryClient.postToPMS(claim.wieldyId, () => {
      updateSnackBar({
        severity: "info",
        message: "Still posting claim.",
      });
    });
    if (postingStatusInProgress(postResponse)) {
      updateSnackBar({
        severity: "info",
        message: `Hang tight. Claim ${claim.claimId} is posting...`,
      });
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedAttempts: postResponse.postedAttempts,
          postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
          updatedAt: isoNow(),
        },
      });
      setIsPolling(true);
      return;
    }
    if (!postingStatusSuccess(postResponse)) {
      updateSnackBar({
        severity: "error",
        message:
          "There was an issue posting to PMS. Please reach out to Wieldy " +
          "support.",
      });
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedAttempts: postResponse.postedAttempts,
          postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
          updatedAt: isoNow(),
        },
      });
      return;
    }
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: {
        ...claim,
        postedState: postResponse.postedState,
        postedDateTime: postResponse.postedDateTime,
        postedAttempts: postResponse.postedAttempts,
      },
    });
    updateSnackBar({
      severity: "success",
      message: "Successfully posted to PMS",
    });
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response?.data) {
      const responseBody = error.response.data;
      updateSnackBar({
        severity: "error",
        message: responseBody.postedLatestErrorMessage,
      });
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: responseBody.postedState,
          postedAttempts: responseBody.postedAttempts,
          postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
        },
      });
    }
  }
}
