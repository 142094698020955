import Grid from "@mui/material/Grid";
import React, { useState } from "react";

import ProceduresTable from "./ProceduresTable";
import textArea from "./textArea";

function extractProcedures(
  claim: ClaimWithProcedureAndPatientMessage,
): ProcedureMessage[] {
  const procedures = claim.Procedure || [];
  if (claim.claimLineItem) {
    // We surface claim line items (which represent office visit payments) as
    // a procedure.
    return [
      ...procedures,
      {
        wieldyId: `${claim.wieldyId}.officevisit`,
        procedureContractedAmount: 0,
        procedureDate: null,
        procedureCode: "Office Visit",
        procedureToothNumber: null,
        procedureDenialCode: null,
        procedureDenialDescription: null,
        procedureDeductibleAmount: null,
        procedurePatientCoinsurance: null,
        procedurePatientCopay: null,
        procedurePatientNoncovered: null,
        procedurePatientResponsibility: 0,
        procedurePayerPays: claim.claimLineItem,
        procedureStatus: null,
        procedureSubmittedAmount: 0,
      },
    ];
  }
  return procedures;
}

interface ClaimDetailsProps {
  claim: ClaimWithProcedureAndPatientMessage;
  onClaimDetailUpdate: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  onSaveProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  onDeleteProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  practices: PracticeMessage[];
}

export default function ClaimDetails({
  claim,
  onClaimDetailUpdate,
  onSaveProcedure,
  onDeleteProcedure,
  practices,
}: ClaimDetailsProps) {
  const [editNotes, enabledEditNotes] = useState(false);
  const [updatedNotesText, setUpdatedNotesText] = useState<string>(
    claim.notes ?? "",
  );

  const editAndSaveNotes = (isSave: boolean) => {
    if (isSave && claim.notes !== updatedNotesText) {
      onClaimDetailUpdate(claim, {
        wieldyId: claim.wieldyId,
        practiceId: claim.practiceId,
        notes: updatedNotesText,
      });
    }
    enabledEditNotes(!editNotes);
  };

  return (
    <>
      <Grid
        container
        spacing={0.5}
        style={{
          backgroundColor: "#F9FAFB",
          minHeight: "70px",
          fontSize: "12px",
        }}
      >
        <Grid item xs={12} style={{ padding: "16px" }}>
          {textArea(
            "Notes",
            updatedNotesText,
            setUpdatedNotesText,
            editNotes,
            editAndSaveNotes,
          )}
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ProceduresTable
            procedures={extractProcedures(claim)}
            isLoading={!claim.Procedure}
            onSaveProcedure={onSaveProcedure}
            onDeleteProcedure={onDeleteProcedure}
            practices={practices}
          />
        </Grid>
      </Grid>
    </>
  );
}
