import { partialReplaceByWieldyId } from "../../utils/utils";

export type ReconciliationsStore = {
  transactions: BankTransactionMessage[];
  loadedTransactions: boolean;
  pagination?: {
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
};

export const initialReconciliationsStore: ReconciliationsStore = {
  transactions: [],
  loadedTransactions: false,
};

export type ReconciliationsStoreAction =
  | {
      type: "SET_TRANSACTIONS";
      transactions: BankTransactionMessage[];
      pagination?: {
        total: number;
        page: number;
        pageSize: number;
        totalPages: number;
      };
    }
  | { type: "RESET_TRANSACTIONS" }
  | {
      type: "UPDATE_TRANSACTION";
      transactionUpdate: BankTransactionUpdateMessage;
    }
  | {
      type: "APPEND_TRANSACTIONS";
      transactions: BankTransactionMessage[];
    };

export function reconciliationsStoreReducer(
  store: ReconciliationsStore,
  action: ReconciliationsStoreAction,
): ReconciliationsStore {
  switch (action.type) {
    case "RESET_TRANSACTIONS":
      return {
        ...store,
        transactions: [],
        loadedTransactions: false,
        pagination: undefined,
      };
    case "SET_TRANSACTIONS":
      return {
        ...store,
        transactions: action.transactions,
        loadedTransactions: true,
        pagination: action.pagination,
      };
    case "APPEND_TRANSACTIONS":
      return {
        ...store,
        transactions: [...store.transactions, ...action.transactions],
        loadedTransactions: true,
      };
    case "UPDATE_TRANSACTION":
      return {
        ...store,
        transactions: partialReplaceByWieldyId(
          store.transactions,
          action.transactionUpdate,
        ),
      };
    default:
      return store;
  }
}
