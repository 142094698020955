export enum BankMatchState {
  NOT_CLEARED,
  AUTO_CLEARED,
}

export enum BankTransactionType {
  INSURANCE,
  OTHER,
}

export enum BankTransactionStatus {
  MATCHED,
  POSTED_OUTSIDE,
  MISSING,
}
