import { replaceByWieldyId } from "../../utils/utils";

export type ClaimsStore = {
  claims: ClaimWithProcedureAndPatientMessage[];
  loadedClaims: boolean;
};

export const initialClaimsStore: ClaimsStore = {
  claims: [],
  loadedClaims: false,
};

export type ClaimsStoreAction =
  | { type: "RESET_CLAIMS" }
  | { type: "SET_CLAIMS"; claims: ClaimWithProcedureAndPatientMessage[] }
  | { type: "APPEND_CLAIMS"; claims: ClaimWithProcedureAndPatientMessage[] }
  | {
      type: "UPDATE_CLAIM";
      claimToUpdate: ClaimWithProcedureAndPatientMessage;
    }
  | {
      type: "CREATE_CLAIM";
      createdClaim: ClaimWithProcedureAndPatientMessage;
    }
  | {
      type: "DELETE_CLAIM";
      claimKey: string;
    }
  | {
      type: "UPDATE_PROCEDURE";
      claimKey: string;
      procedure: ProcedureMessage;
    }
  | {
      type: "CREATE_PROCEDURE";
      claimKey: string;
      procedure: ProcedureMessage;
    }
  | {
      type: "DELETE_PROCEDURE";
      claimKey: string;
      procedureKey: string;
    };

export function claimsStoreReducer(
  store: ClaimsStore,
  action: ClaimsStoreAction,
): ClaimsStore {
  switch (action.type) {
    case "SET_CLAIMS":
      return {
        ...store,
        claims: action.claims,
        loadedClaims: true,
      };
    case "APPEND_CLAIMS":
      return {
        ...store,
        claims: [...store.claims, ...action.claims],
      };
    case "RESET_CLAIMS":
      return {
        ...store,
        claims: [],
        loadedClaims: false,
      };
    case "UPDATE_CLAIM":
      return {
        ...store,
        claims: replaceByWieldyId(store.claims, action.claimToUpdate) ?? [
          action.claimToUpdate,
        ],
      };
    case "CREATE_CLAIM":
      return {
        ...store,
        claims: [...store.claims, action.createdClaim],
      };
    case "DELETE_CLAIM":
      return {
        ...store,
        claims: store.claims.filter(
          (claim) => claim.wieldyId !== action.claimKey,
        ),
      };
    case "UPDATE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: claim.Procedure.map((proc) =>
                proc.wieldyId === action.procedure.wieldyId
                  ? action.procedure
                  : proc,
              ),
            };
          }
          return claim;
        }),
      };
    case "CREATE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: [...claim.Procedure, action.procedure],
            };
          }
          return claim;
        }),
      };
    case "DELETE_PROCEDURE":
      return {
        ...store,
        claims: store.claims.map((claim) => {
          if (claim.wieldyId === action.claimKey) {
            return {
              ...claim,
              Procedure: claim.Procedure.filter(
                (procedure) => procedure.wieldyId !== action.procedureKey,
              ),
            };
          }
          return claim;
        }),
      };
    default:
      return store;
  }
}
