import { useEffect, useRef, useState } from "react";

import QueryClient from "../api/query/queryClient";
import {
  MAX_POLL_TIME,
  needsPolling,
  POLL_INTERVAL,
  PostedState,
} from "./posting";

/**
 * We optimistically update claims on the FE and the BE is eventually consistent.
 * This means the FE and BE can be out of sync. In order to make sure we're
 * only using the most up-to-date data, we use data with the latest
 * update_at date time.
 */
function latestClaims(
  backendClaims: ClaimWithProcedureAndPatientMessage[],
  frontendClaims: ClaimWithProcedureAndPatientMessage[],
): Record<string, ClaimWithProcedureAndPatientMessage> {
  const backendClaimsMap = backendClaims.reduce(
    (acc, curr) => {
      acc[curr.wieldyId] = curr;
      return acc;
    },
    {} as Record<string, ClaimWithProcedureAndPatientMessage>,
  );
  const frontendClaimsMap = frontendClaims.reduce<
    Record<string, ClaimWithProcedureAndPatientMessage>
  >((acc, curr) => {
    if (backendClaimsMap[curr.wieldyId]) {
      acc[curr.wieldyId] = curr;
    }
    return acc;
  }, {});
  return (
    Object.values(backendClaimsMap) as ClaimWithProcedureAndPatientMessage[]
  ).reduce(
    (acc, backendClaim) => {
      const frontendClaim = frontendClaimsMap[backendClaim.wieldyId];
      if (frontendClaim.updatedAt && backendClaim.updatedAt) {
        const pollingDate = new Date(frontendClaim.updatedAt);
        const claimDate = new Date(backendClaim.updatedAt);
        if (claimDate > pollingDate) {
          acc[backendClaim.wieldyId] = backendClaim;
        } else {
          acc[backendClaim.wieldyId] = frontendClaim;
        }
      } else {
        acc[backendClaim.wieldyId] = backendClaim;
      }
      return acc;
    },
    {} as Record<string, ClaimWithProcedureAndPatientMessage>,
  );
}

interface UseClaimsPollingProps {
  storeClaims: ClaimWithProcedureAndPatientMessage[];
  queryClient: QueryClient;
  updateStore: (action: {
    type: "UPDATE_CLAIM";
    claimToUpdate: ClaimWithProcedureAndPatientMessage;
  }) => void;
  updateSnackBar: (snackbar: {
    severity: "success" | "error" | "info";
    message: string;
  }) => void;
}

export function useClaimsPolling({
  storeClaims,
  queryClient,
  updateStore,
  updateSnackBar,
}: UseClaimsPollingProps) {
  const pollStartTimeRef = useRef<number | null>(null);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  useEffect(() => {
    if (!isPolling) {
      pollStartTimeRef.current = null;
      return () => {};
    }

    if (!pollStartTimeRef.current) {
      pollStartTimeRef.current = Date.now();
    }
    const pollInterval = setInterval(async () => {
      if (
        pollStartTimeRef.current &&
        Date.now() - pollStartTimeRef.current > MAX_POLL_TIME
      ) {
        setIsPolling(false);
        pollStartTimeRef.current = null;
      }
      const claimsToPoll = storeClaims
        .filter((claim) => needsPolling(claim))
        .map((claim) => claim.wieldyId);
      const searchResponse =
        await queryClient.getClaimsProceduresAndPatient(claimsToPoll);
      const claims = latestClaims(searchResponse.data, storeClaims);
      const completedClaims = (
        Object.values(claims) as ClaimWithProcedureAndPatientMessage[]
      ).filter((claim) => !needsPolling(claim));

      const allPolling = (
        Object.values(claims) as ClaimWithProcedureAndPatientMessage[]
      ).reduce<Record<string, ClaimWithProcedureAndPatientMessage>>(
        (acc, claim) => {
          if (needsPolling(claim)) {
            acc[claim.wieldyId] = claim;
          }
          return acc;
        },
        {},
      );
      setIsPolling(!!Object.keys(allPolling).length);
      completedClaims.forEach((claimToUpdate) => {
        updateStore({
          claimToUpdate,
          type: "UPDATE_CLAIM",
        });
      });
      completedClaims.forEach((claim) => {
        if (claim.postedState === PostedState[PostedState.AUTO_POSTED]) {
          updateSnackBar({
            severity: "success",
            message: "Successfully posted to PMS",
          });
        }
        if (
          [
            PostedState[PostedState.FAILURE],
            PostedState[PostedState.RETRY],
          ].includes(claim.postedState)
        ) {
          updateSnackBar({
            severity: "error",
            message:
              claim.postedLatestErrorMessage ||
              "There was an issue posting to PMS. Please reach out to Wieldy support.",
          });
        }
      });
      if (completedClaims.length === 0 && Object.keys(allPolling).length > 0) {
        const postingClaims = Object.values(allPolling).filter(
          (claim: ClaimWithProcedureAndPatientMessage) =>
            claim.postedState === PostedState[PostedState.POSTING],
        );
        updateSnackBar({
          severity: "info",
          message: `Posting ${postingClaims.length} of ${Object.keys(allPolling).length} claims...`,
        });
      }
    }, POLL_INTERVAL);
    return () => {
      clearInterval(pollInterval);
    };
  }, [isPolling, storeClaims]);

  return setIsPolling;
}
