import { FormControl, Select, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../formatters";
import { enumToStrings, isoToDate } from "../../utils/utils";
import { BankTransactionStatus, BankTransactionType } from "../bankMatch";
import { NavigationCell } from "../ClaimsTable/ui/NavigationCell";
import { renderCellRedacted } from "../redact";

function renderTransactionSelect(props: {
  value: string;
  emptyLabel: string;
  options: Array<{ value: string; label?: string }>;
  onChange: (value: string) => void;
  renderCustomValue?: (selected: string) => React.ReactNode;
}): JSX.Element {
  const theme = useTheme();
  const { value, emptyLabel, options, onChange, renderCustomValue } = props;

  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        value={value}
        renderValue={(selected) => {
          if (!value) {
            return (
              <em style={{ color: theme.palette.text.disabled }}>
                {emptyLabel}
              </em>
            );
          }

          if (renderCustomValue) {
            return renderCustomValue(selected as string);
          }

          return selected as string;
        }}
        onChange={(e) => onChange(e.target.value as string)}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: value ? 0 : `1px solid ${theme.palette.error.main}`,
          },
          borderRadius: "12px",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label || option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function renderTransactionTypeSelect(
  props: GridRenderCellParams,
  transactionUpdate: (transactionUpdate: BankTransactionUpdateMessage) => void,
): JSX.Element {
  const theme = useTheme();
  const { value, row: transaction } = props;

  if (transaction.status === "MATCHED") {
    return (
      <div style={{ padding: "8px 14px", color: theme.palette.text.primary }}>
        {value}
      </div>
    );
  }

  return renderTransactionSelect({
    value,
    emptyLabel: "Select type",
    options: enumToStrings(BankTransactionType).map((option) => ({
      value: option,
    })),
    onChange: (newValue) => {
      const { wieldyId, practiceId } = transaction;
      transactionUpdate({
        wieldyId,
        practiceId,
        transactionType: newValue as keyof typeof BankTransactionType,
      });
    },
  });
}

const STATUS_OPTIONS = enumToStrings(BankTransactionStatus)
  .filter((option) => option !== "MATCHED")
  .map((option) => {
    let label: string = option;

    switch (option) {
      case "POSTED_OUTSIDE":
        label = "Posted Outside Copilot";
        break;
      case "MISSING":
        label = "Report Missing";
        break;
      default:
        label = option;
    }

    return {
      value: option,
      label,
    };
  });

function renderStatusSelect(
  props: GridRenderCellParams,
  transactionUpdate: (transactionUpdate: BankTransactionUpdateMessage) => void,
): JSX.Element {
  const theme = useTheme();
  const { value, row: transaction } = props;

  if (transaction.transactionType === "OTHER" || value === "MATCHED") {
    return (
      <div
        style={{
          padding: "8px 14px",
          color:
            value === "MATCHED"
              ? theme.palette.success.main
              : theme.palette.text.secondary,
        }}
      >
        {value === "MATCHED" ? "Matched" : "-"}
      </div>
    );
  }

  return renderTransactionSelect({
    value,
    emptyLabel: "Select status",
    options: STATUS_OPTIONS,
    onChange: (selectedValue) => {
      const { wieldyId, practiceId } = transaction;
      transactionUpdate({
        wieldyId,
        practiceId,
        status: selectedValue as keyof typeof BankTransactionStatus,
      });
    },
    renderCustomValue: (selected) => {
      const selectedOption = STATUS_OPTIONS.find(
        (option) => option.value === selected,
      );
      return selectedOption ? selectedOption.label : selected;
    },
  });
}

export const COLUMN_DEFAULT_VISIBILITY = {
  practice: true,
  bankDate: true,
  checkNumber: true,
  description: true,
  amount: true,
  paymentKey: false,
  transactionType: true,
  status: true,
  bankName: true,
};

export function getDefaultColumns(
  practiceValueOptions: string[],
  searchParams: URLSearchParams,
  transactionUpdate: (transactionUpdate: BankTransactionUpdateMessage) => void,
): GridColDef[] {
  return [
    {
      flex: 0.75,
      field: "practice",
      headerName: "Practice",
      type: "singleSelect",
      valueOptions: practiceValueOptions.length
        ? practiceValueOptions
        : undefined,
    },
    {
      flex: 0.75,
      type: "date",
      field: "bankDate",
      headerName: "Bank Date",
      valueGetter: ({ value }: GridRenderCellParams) =>
        value ? isoToDate(value) : "",
    },
    {
      flex: 0.5,
      field: "checkNumber",
      headerName: "Check Number",
    },
    {
      flex: 2,
      field: "description",
      headerName: "Description",
      renderCell: (props: GridRenderCellParams) => renderCellRedacted(props),
    },
    {
      flex: 0.75,
      type: "number",
      field: "amount",
      headerName: "Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 0.75,
      field: "bankName",
      headerName: "Bank Name",
    },
    {
      flex: 1,
      type: "singleSelect",
      field: "transactionType",
      headerName: "Transaction Type",
      valueOptions: enumToStrings(BankTransactionType),
      renderCell: (props: GridRenderCellParams) =>
        renderTransactionTypeSelect(props, transactionUpdate),
    },
    {
      flex: 1,
      type: "singleSelect",
      field: "status",
      headerName: "Status",
      valueOptions: STATUS_OPTIONS,
      renderCell: (props: GridRenderCellParams) =>
        renderStatusSelect(props, transactionUpdate),
    },
    {
      flex: 1,
      field: "paymentKey",
      headerName: "Payment ID",
      renderCell: ({ value }: GridRenderCellParams) => {
        if (value) {
          const paymentId = value.split(".")[1];
          return (
            <NavigationCell
              linkPath={`/payments/${value}?${searchParams}`}
              displayText={paymentId}
              ariaLabel="View payment details"
            />
          );
        }
        return <div>-</div>;
      },
    },
  ];
}
