import type QueryClient from "../api/query/queryClient";

export function claimKeyFromProcedureKey(procedureKey: string): string | null {
  const wieldyIdParts = procedureKey.split(".");
  if (wieldyIdParts.length !== 4) {
    return null;
  }
  return wieldyIdParts.slice(0, 3).join(".");
}

export async function deleteClaimAndNotify(
  claim: ClaimWithProcedureAndPatientMessage,
  queryClient: QueryClient,
  updateStore: (action: { type: "DELETE_CLAIM"; claimKey: string }) => void,
  updateSnackBar: (snackbar: {
    severity: "success" | "error" | "info";
    message: string;
  }) => void,
) {
  updateStore({
    type: "DELETE_CLAIM",
    claimKey: claim.wieldyId,
  });
  const claimKey = await queryClient.deleteClaim(claim);
  updateSnackBar({
    severity: "success",
    message: `Successfully deleted claim: ${claimKey}`,
  });
}

export async function deleteProcedureAndNotify(
  procedure: ProcedureMessage,
  claims: ClaimWithProcedureAndPatientMessage[],
  queryClient: QueryClient,
  updateStore: (action: {
    type: "DELETE_PROCEDURE";
    claimKey: string;
    procedureKey: string;
  }) => void,
  updateSnackBar: (snackbar: {
    severity: "success" | "error" | "info";
    message: string;
  }) => void,
) {
  const claimKey = claimKeyFromProcedureKey(procedure.wieldyId);
  if (!claimKey) {
    updateSnackBar({
      severity: "error",
      message: `Invalid Wieldy ID for procedure: ${procedure.wieldyId}`,
    });
    return;
  }
  const existingClaim = claims.find((c) => c.wieldyId === claimKey);
  if (!existingClaim) {
    updateSnackBar({
      severity: "error",
      message: `Claim not found for procedure: ${claimKey}`,
    });
    return;
  }
  updateStore({
    type: "DELETE_PROCEDURE",
    claimKey,
    procedureKey: procedure.wieldyId,
  });
  const procedureKey = await queryClient.deleteProcedure(
    procedure,
    existingClaim.practiceId,
  );
  updateSnackBar({
    severity: "success",
    message: `Successfully deleted procedure: ${procedureKey}`,
  });
}
