import "./App.css";
import "./index.css";

import { Alert, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import Snackbar from "@mui/material/Snackbar";
import { gridClasses } from "@mui/x-data-grid-pro";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { getBackend } from "./api/apiConfig";
import MaintenancePage from "./components/MaintenancePage";
import { PatientPortal } from "./components/PatientPortal";
import FCPortal from "./FCPortal";
import { isDemoMode } from "./utils/utils";

// Visual distinction so we don't accidentally do things in the wrong env.
const BACKGROUND_COLOR = isDemoMode()
  ? "#ffffff"
  : ["#ffc0cb", "#cbc0ff", "#ffffff"][getBackend()];

document.body.style.backgroundColor = BACKGROUND_COLOR;

const theme = createTheme({
  palette: {
    background: {
      default: BACKGROUND_COLOR,
    },
    primary: {
      main: "#25233F",
    },
    secondary: {
      main: "#E9D7D9",
    },
    success: {
      main: "#12B76A",
    },
  },
  typography: {
    fontFamily: ["Inter Regular", "Inter", "Roboto"].join(","),
    fontSize: 14,
  },
  transitions: {
    duration: {
      leavingScreen: 700,
      enteringScreen: 700,
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: BACKGROUND_COLOR,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSuccess: {
          color: "#FFF",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          fontSize: "1rem",
          weight: "500",
          lineHeight: "20px",
          color: "#475467",
        },
        row: {
          backgroundColor: "#FFF",
          boxShadow: "0px 1px 2px 0px #1018280F",
          border: "0px",
          borderRadius: "12px",
        },
        cell: {
          borderBottom: "none",
        },
        footerContainer: {
          border: "0px",
        },
        main: {
          [`& .${gridClasses.columnHeaderTitle}`]: {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          [`& .${gridClasses.columnHeader}`]: {
            height: "unset !important",
          },
          [`&>.${gridClasses.columnHeaders}`]: {
            borderBottom: "none",
            backgroundColor: "#F2F4F7",
            marginBottom: "8px",
            borderRadius: "12px",
            fontSize: "1rem",
            color: "#303030",
            maxHeight: "168px !important",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: 500,
          backgroundColor: "#EAECF0",
          padding: "6px 16px",
          color: "#475467",
          "&.Mui-selected": {
            backgroundColor: "#25233F",
            color: "#FFF",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#25233F",
            color: "#FFF",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: "4px",
          borderRadius: "8px",
          backgroundColor: "#EEE",
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "#09080F",
          padding: "8px 16px",
          "&.Mui-selected": {
            color: "#FFF",
            backgroundColor: "#4A4A62",
          },
        },
      },
    },
  },
});

export default function App() {
  const [snackbar, updateSnackBar] = useState<{
    severity: AlertColor;
    message: string;
  } | null>(null);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <Routes>
            <Route
              path="/patient-portal/:sessionId/*"
              element={<PatientPortal updateSnackBar={updateSnackBar} />}
            />
            <Route path="/maintenance" element={<MaintenancePage />} />
            {/* This is the main route for the app (FC Portal). It will render the left nav and the main view. */}
            <Route
              path="*"
              element={<FCPortal updateSnackBar={updateSnackBar} />}
            />
          </Routes>
        </Router>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={!!snackbar}
          onClose={() => {
            updateSnackBar(null);
          }}
        >
          {snackbar ? (
            <Alert
              onClose={() => {
                updateSnackBar(null);
              }}
              severity={snackbar ? snackbar.severity : undefined}
              sx={{ width: "100%" }}
            >
              {snackbar ? snackbar.message : ""}
            </Alert>
          ) : (
            <div />
          )}
        </Snackbar>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
