import React, { useEffect, useState } from "react";

import QueryClient from "../../api/query/queryClient";
import Loading from "../Loading";
import PageNotFound from "../PageNotFound";

const METABASE_SITE_URL = "https://wieldy.metabaseapp.com";

interface MetabaseViewProps {
  queryClient: QueryClient;
}

export default function MetabaseView({ queryClient }: MetabaseViewProps) {
  const [iframeUrl, setIframeUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    queryClient
      .getMetabaseToken()
      .then((res) => {
        const url =
          `${METABASE_SITE_URL}/embed/dashboard/${res.token}` +
          `#bordered=true&titled=true`;
        setIframeUrl(url);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: "flex", height: "80vh" }}>
        <Loading />
      </div>
    );
  }

  if (!iframeUrl) {
    return <PageNotFound />;
  }

  return (
    <>
      <h1>Reports</h1>
      <iframe
        title="Metabase"
        src={iframeUrl}
        style={{
          width: "100%",
          height: "100vh",
          border: "0px",
        }}
        // eslint-disable-next-line react/no-unknown-property
        allowTransparency
      />
    </>
  );
}
