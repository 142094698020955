import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import dayjs, { type Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useQueryClient from "../../api/query";

dayjs.extend(utc);
dayjs.extend(timezone);

function getTimezone(): string | undefined {
  const userTimeZone = dayjs.tz.guess();
  const dtf = new Intl.DateTimeFormat("en-US", {
    timeZone: userTimeZone,
    timeZoneName: "short",
  });
  const parts = dtf.formatToParts(new Date());
  return parts.find((part) => part.type === "timeZoneName")?.value;
}

function formatTimestamp(timestamp: Dayjs): string {
  let formatted = timestamp.format("MMMM D, YYYY - h:mm A");
  const tz = getTimezone();
  if (tz) {
    formatted = `${formatted} ${tz}`;
  }
  return formatted;
}

function getTimeRemaining(maintenanceEnd: Dayjs): string {
  const currentTime = dayjs();
  const diffInMinutes = maintenanceEnd.diff(currentTime, "minute");
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  return `${hours}h ${minutes}m`;
}

export default function MaintenancePage() {
  const [maintenanceStart, setMaintenanceStart] = useState<Dayjs>();
  const [maintenanceEnd, setMaintainenanceEnd] = useState<Dayjs>();
  const [loaded, setLoaded] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    queryClient.getOrganization().then((organization) => {
      if (!organization.inMaintenance) {
        navigate("/");
        return;
      }
      if (organization.maintenanceStart) {
        setMaintenanceStart(dayjs(organization.maintenanceStart));
      }
      if (organization.maintenanceEnd) {
        setMaintainenanceEnd(dayjs(organization.maintenanceEnd));
      }
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#f8fafb" }}>
      <Container
        sx={{
          height: "100vh",
          padding: theme.spacing(4),
        }}
      >
        <Card>
          <CardHeader
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              justifyContent: "center",
              "& .MuiCardHeader-avatar": { marginRight: theme.spacing(1) },
              "& .MuiCardHeader-content": { flex: "initial" },
              "& .MuiTypography-root": { fontWeight: 600 },
            }}
            avatar={<WarningAmberRoundedIcon fontSize="large" />}
            title={
              <Typography variant="h5">
                System Temporarily Unavailable
              </Typography>
            }
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontWeight: 600, marginBottom: theme.spacing(2) }}
              variant="h6"
            >
              Critical system maintenance in progress
            </Typography>
            {maintenanceStart && (
              <Typography
                sx={{ fontWeight: 600, marginBottom: theme.spacing(2) }}
              >
                <CalendarTodayRoundedIcon
                  sx={{
                    position: "relative",
                    top: "4px",
                    marginRight: theme.spacing(1),
                  }}
                  fontSize="small"
                />
                Started: {formatTimestamp(maintenanceStart)}
              </Typography>
            )}
            {maintenanceEnd && (
              <Typography
                sx={{ fontWeight: 600, marginBottom: theme.spacing(2) }}
              >
                <TaskAltRoundedIcon
                  sx={{
                    position: "relative",
                    top: "4px",
                    marginRight: theme.spacing(1),
                  }}
                  fontSize="small"
                  color="success"
                />
                Estimated completion: {formatTimestamp(maintenanceEnd)}
              </Typography>
            )}
            {maintenanceEnd && (
              <Paper
                variant="outlined"
                sx={{
                  display: "inline-flex",
                  padding: theme.spacing(2),
                  backgroundColor: "#dae6e3",
                  border: "2px solid #142a22",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  <CheckCircleOutlineRoundedIcon
                    sx={{
                      position: "relative",
                      top: "6px",
                      marginRight: theme.spacing(1),
                    }}
                  />
                  Estimated time remaining: {getTimeRemaining(maintenanceEnd)}
                </Typography>
              </Paper>
            )}
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "center",
              padding: theme.spacing(2),
              marginTop: theme.spacing(8),
              backgroundColor: "#e7ede0",
            }}
          >
            <Typography sx={{ color: "#1c301d" }}>
              We apologize for the inconvenience. Our team is working diligently
              to restore full functionality.
            </Typography>
          </CardActions>
        </Card>
      </Container>
    </div>
  );
}
