import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { PREDEFINED_BANK_TRANSACTION_FILTERS } from "../../status";
import DateRangePicker, { DateRange } from "../DateRangePicker";
import ReconciliationsTable from "../ReconciliationsTable";
import NewReconciliation from "./NewReconciliation";

function filterTransactions(
  transactions: BankTransactionMessage[],
  dateRange: DateRange,
): BankTransactionMessage[] {
  return transactions.filter((transaction) => {
    if (!transaction.bankDate) {
      return false;
    }
    const bankDate = dayjs(transaction.bankDate);
    return (
      (bankDate.isSame(dateRange.startDate, "day") ||
        bankDate.isAfter(dateRange.startDate, "day")) &&
      (bankDate.isSame(dateRange.endDate, "day") ||
        bankDate.isBefore(dateRange.endDate, "day"))
    );
  });
}

interface ReconciliationListViewProps {
  load: (page?: number, pageSize?: number) => Promise<void>;
  reset: () => void;
  transactions: BankTransactionMessage[];
  isLoading: boolean;
  transactionsLoading: boolean;
  practices: PracticeMessage[];
  uploadBankTransactions: (practiceId: string, file: File) => Promise<void>;
  updateTransaction: (
    transactionUpdate: BankTransactionUpdateMessage,
  ) => Promise<void>;
  pagination?: {
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
}

export default function ReconciliationListView({
  load,
  reset,
  transactions,
  isLoading,
  transactionsLoading,
  practices,
  uploadBankTransactions,
  updateTransaction,
  pagination,
}: ReconciliationListViewProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [updatedTransactionIds, setUpdatedTransactionIds] = useState<
    Set<string>
  >(new Set());

  const handleTransactionUpdate = async (
    transactionUpdate: BankTransactionUpdateMessage,
  ) => {
    await updateTransaction(transactionUpdate);
    setUpdatedTransactionIds((prev) =>
      new Set(prev).add(transactionUpdate.wieldyId),
    );
  };

  useEffect(() => {
    load();
    return () => {
      reset();
      setUpdatedTransactionIds(new Set());
    };
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] =
    useState<PREDEFINED_BANK_TRANSACTION_FILTERS>(
      PREDEFINED_BANK_TRANSACTION_FILTERS.ALL,
    );

  const filterFromQueryParam = (): PREDEFINED_BANK_TRANSACTION_FILTERS => {
    const statusParam = searchParams.get("status");
    if (!statusParam) {
      return PREDEFINED_BANK_TRANSACTION_FILTERS.ALL;
    }
    const formattedStatus =
      statusParam.charAt(0).toUpperCase() + statusParam.slice(1).toLowerCase();
    return Object.values(PREDEFINED_BANK_TRANSACTION_FILTERS).includes(
      formattedStatus as PREDEFINED_BANK_TRANSACTION_FILTERS,
    )
      ? (formattedStatus as PREDEFINED_BANK_TRANSACTION_FILTERS)
      : PREDEFINED_BANK_TRANSACTION_FILTERS.ALL;
  };

  useEffect(() => {
    setSelectedFilter(filterFromQueryParam());
  }, []);

  const earliestStartDate = useMemo(
    () =>
      transactions.length
        ? transactions[transactions.length - 1].bankDate
        : null,
    [transactions],
  );

  const [currentDateRange, setCurrentDateRange] = useState<DateRange>({
    startDate: earliestStartDate
      ? dayjs(earliestStartDate)
      : dayjs().subtract(1, "year"),
    endDate: dayjs(),
  });

  const isFiltering = useMemo(() => {
    if (!currentDateRange.startDate || !currentDateRange.endDate) {
      return false;
    }

    const isDefaultRange =
      currentDateRange.startDate.isSame(dayjs().subtract(1, "year"), "day") &&
      currentDateRange.endDate.isSame(dayjs(), "day");

    return !isDefaultRange;
  }, [currentDateRange]);

  const filteredTransactions = useMemo(() => {
    const dateFilteredTransactions =
      currentDateRange.startDate && currentDateRange.endDate
        ? filterTransactions(transactions, currentDateRange)
        : transactions;

    if (selectedFilter === PREDEFINED_BANK_TRANSACTION_FILTERS.REVIEW) {
      return dateFilteredTransactions.filter(
        (transaction) =>
          transaction.transactionType === "INSURANCE" &&
          (updatedTransactionIds.has(transaction.wieldyId) ||
            transaction.status === null),
      );
    }

    if (selectedFilter === PREDEFINED_BANK_TRANSACTION_FILTERS.MATCHED) {
      return dateFilteredTransactions.filter(
        (transaction) => transaction.status === "MATCHED",
      );
    }

    return dateFilteredTransactions;
  }, [transactions, currentDateRange, selectedFilter, updatedTransactionIds]);

  return (
    <>
      <h1>Bank Reconciliation</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "14px",
        }}
      >
        <div>
          <Button
            aria-label="Upload New Bank Statement"
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            style={{ marginBottom: "14px" }}
          >
            Upload New Bank Statement
          </Button>

          <div>
            <ToggleButtonGroup
              size="medium"
              color="primary"
              value={selectedFilter}
              exclusive
              onChange={(_, newfilter) => {
                if (newfilter && selectedFilter !== newfilter) {
                  const params = new URLSearchParams(location.search);
                  params.set("status", newfilter.toLowerCase());
                  navigate({ search: params.toString() }, { replace: true });
                  setSelectedFilter(newfilter);
                }
              }}
              aria-label="Bank Transaction Filters"
            >
              {Object.values(PREDEFINED_BANK_TRANSACTION_FILTERS).map(
                (filter) => (
                  <ToggleButton
                    key={filter}
                    value={filter}
                    aria-label={`${filter} filter`}
                  >
                    {filter}
                  </ToggleButton>
                ),
              )}
            </ToggleButtonGroup>
          </div>
        </div>

        <div>
          <DateRangePicker
            selectedDateRange={currentDateRange}
            setDateRange={setCurrentDateRange}
            earliestStartDate={earliestStartDate}
          />
        </div>
      </div>

      <NewReconciliation
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        practices={practices}
        uploadBankTransactions={uploadBankTransactions}
      />
      <ReconciliationsTable
        persistKey="reconciliationListView"
        isLoading={transactionsLoading || (isFiltering && isLoading)}
        transactions={filteredTransactions}
        updateTransaction={handleTransactionUpdate}
        rowCount={
          !isLoading && filteredTransactions
            ? filteredTransactions.length
            : pagination?.total || 0
        }
      />
    </>
  );
}
