import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AlertColor } from "@mui/material/Alert/Alert";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import dayjs from "dayjs";
import React, { useEffect, useReducer, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { USE_AUTH } from "./api/apiConfig";
import useQueryClient from "./api/query";
import ClaimsDashboard from "./components/ClaimsDashboard";
import {
  claimsStoreReducer,
  initialClaimsStore,
} from "./components/ClaimsDashboard/store";
import CopilotAssist from "./components/CoPilotAssist";
import { CredentialsDashboard } from "./components/CredentialsDashboard";
import DocumentsDashboard from "./components/DocumentsDashboard";
import LeftNav from "./components/LeftNav";
import Loading from "./components/Loading";
import PageNotFound from "./components/PageNotFound";
import PatientPayments from "./components/PatientPayments";
import PatientsDashboard, {
  initialPatientStore,
  patientsStoreReducer,
} from "./components/PatientsDashboard";
import PaymentPlans, {
  initialPaymentPlanStore,
  paymentPlanStoreReducer,
} from "./components/PaymentPlans";
import PaymentsDashboard from "./components/PaymentsDashboard";
import { PaymentDashboardSettings } from "./components/PaymentsDashboard/PaymentsListView";
import {
  initialPaymentsStore,
  paymentsStoreReducer,
} from "./components/PaymentsDashboard/store";
import ReconciliationDashboard from "./components/ReconciliationDashboard";
import {
  initialReconciliationsStore,
  reconciliationsStoreReducer,
} from "./components/ReconciliationDashboard/store";
import ReportsDashboard from "./components/ReportsDashboard";
import { persistLocalStorage, retrieveLocalStorage } from "./utils/utils";

const { search } = window.location;
const GLOBAL_SELECTED_PRACTICES = "globalSelectedPractices";

interface DashboardSettings {
  payments: PaymentDashboardSettings;
}

function getDashboardSettings(): DashboardSettings {
  const dashboardSettings: DashboardSettings = {
    payments: {
      selectedDate: {
        startDate: dayjs().subtract(1, "month"),
        endDate: dayjs(),
      },
    },
  };
  const dashboardLocalStorage = localStorage.getItem("dashboardSettings");
  if (dashboardLocalStorage) {
    const persistedDashboardSettings: DashboardSettings = JSON.parse(
      dashboardLocalStorage,
    );
    dashboardSettings.payments.selectedDate.startDate = dayjs(
      persistedDashboardSettings.payments.selectedDate.startDate,
    );
  }
  return dashboardSettings;
}

function FCPortal({
  updateSnackBar,
}: {
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}) {
  const [practices, setPractices] = useState<PracticeMessage[]>([]);
  const [supportedSources, setSupportedSources] = useState<SourceMessage[]>([]);
  const [supportedPayers, setSupportedPayers] = useState<
    CredentialsSupportedPayersMessage[]
  >([]);
  const [selectedPractices, setSelectedPractices] = useState<PracticeMessage[]>(
    [],
  );
  const [selectedLocations, setSelectedLocations] = useState<PracticeMessage[]>(
    [],
  );
  const [dashboardSettings, setDashboardSettings] = useState<DashboardSettings>(
    getDashboardSettings(),
  );

  // We define dashboard specific state at the app level so we don't lose state when we
  // switch tabs.
  const [patientsStore, updatePatientsStore] = useReducer(
    patientsStoreReducer,
    initialPatientStore,
  );

  const [paymentPlanStore, updatePaymentPlanStore] = useReducer(
    paymentPlanStoreReducer,
    initialPaymentPlanStore,
  );

  const [paymentsStore, updatePaymentsStore] = useReducer(
    paymentsStoreReducer,
    initialPaymentsStore,
  );

  const [reconciliationsStore, updateReconciliationsStore] = useReducer(
    reconciliationsStoreReducer,
    initialReconciliationsStore,
  );

  const [claimsStore, updateClaimsStore] = useReducer(
    claimsStoreReducer,
    initialClaimsStore,
  );

  const queryClient = useQueryClient();

  const setGlobalPractices = (newSelectedPractices: PracticeMessage[]) => {
    persistLocalStorage(GLOBAL_SELECTED_PRACTICES, newSelectedPractices);
    setSelectedPractices(newSelectedPractices);
  };

  const loadSupportedSources = async () => {
    const hasNoSources = supportedSources.length === 0;
    if (hasNoSources) {
      try {
        const sources = await queryClient.getSupportedSources();
        if (sources) {
          setSupportedSources(sources);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch source: ${error}`);
      }
    }
  };

  const loadSupportedPayers = async () => {
    const hasNoPayers = supportedPayers.length === 0;
    if (hasNoPayers) {
      try {
        const payers = await queryClient.getCredentialsSupportedPayers();
        if (payers) {
          setSupportedPayers(payers);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch payers: ${error}`);
      }
    }
  };

  const loadPractices = async () => {
    const hasNoPractices = practices.length === 0;
    if (hasNoPractices) {
      try {
        const practiceMessages = await queryClient.getPractices();
        practiceMessages.sort((a, b) => a.name.localeCompare(b.name));
        setPractices(practiceMessages);
        const localStoragePractices = retrieveLocalStorage<PracticeMessage[]>(
          GLOBAL_SELECTED_PRACTICES,
        );
        const loadedSelectedPractices = localStoragePractices
          ? localStoragePractices.filter((item) =>
            practiceMessages.some(
              (loadedItem) => loadedItem.wieldyId === item.wieldyId,
            ),
          )
          : practiceMessages;
        setSelectedPractices(loadedSelectedPractices);
        // TODO: Set actual locations, rather than practices, once supported.
        // See also: https://wieldy.atlassian.net/browse/WP-685
        setSelectedLocations(practiceMessages);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch practices: ${error}`);
      }
    }
  };

  useEffect(() => {
    loadPractices();
  }, [practices]);

  useEffect(() => {
    loadSupportedSources();
  }, [supportedSources]);

  useEffect(() => {
    loadSupportedPayers();
  }, [supportedPayers]);

  useEffect(() => {
    localStorage.setItem(
      "dashboardSettings",
      JSON.stringify(dashboardSettings),
    );
  }, [dashboardSettings]);

  const savePaymentDashboard = (newSettings: PaymentDashboardSettings) => {
    setDashboardSettings({
      ...dashboardSettings,
      payments: newSettings,
    });
  };

  const { error: authError, logout, user } = useAuth0();
  const enablePostie = practices.some((p) => p.enabledPostie);

  if (authError) {
    logout();
    return null;
  }
  return (
    <div style={{ display: "flex" }}>
      <LeftNav
        practices={practices}
        selectedPractices={selectedPractices}
        selectPractices={setGlobalPractices}
        selectedLocations={selectedLocations}
        selectLocations={setSelectedLocations}
      >
        {practices.length ? (
          <div className="view">
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      enablePostie
                        ? `/copilotassist${search}`
                        : `/payments${search}`
                    }
                  />
                }
              />
              <Route
                path="/payments/*"
                element={
                  <PaymentsDashboard
                    store={paymentsStore}
                    updateStore={updatePaymentsStore}
                    queryClient={queryClient}
                    loadPractices={loadPractices}
                    selectedLocations={selectedLocations}
                    selectedPractices={selectedPractices}
                    dashboardSettings={dashboardSettings.payments}
                    saveDashboardSettings={savePaymentDashboard}
                    updateSnackBar={updateSnackBar}
                    supportedPayers={supportedPayers}
                  />
                }
              />
              <Route
                path="/patient-payments"
                element={
                  <PatientPayments
                    updateSnackBar={updateSnackBar}
                    paymentPlanStore={paymentPlanStore}
                  />
                }
              />
              <Route
                path="/patients/:patientId/payment-plans/*"
                element={
                  <PaymentPlans
                    patientStore={patientsStore}
                    updatePatientStore={updatePatientsStore}
                    store={paymentPlanStore}
                    updateStore={updatePaymentPlanStore}
                    queryClient={queryClient}
                    updateSnackBar={updateSnackBar}
                  />
                }
              />
              <Route
                path="/patients/*"
                element={
                  <PatientsDashboard
                    store={patientsStore}
                    paymentPlanStore={paymentPlanStore}
                    updateStore={updatePatientsStore}
                    updatePaymentPlanStore={updatePaymentPlanStore}
                    queryClient={queryClient}
                    updateSnackBar={updateSnackBar}
                    practices={practices}
                    selectedPractices={selectedPractices}
                  />
                }
              />
              <Route path="/documents" element={<DocumentsDashboard />} />
              <Route
                path="/claims/*"
                element={
                  <ClaimsDashboard
                    store={claimsStore}
                    updateStore={updateClaimsStore}
                    queryClient={queryClient}
                    updateSnackBar={updateSnackBar}
                    loadPractices={loadPractices}
                    selectedPractices={selectedPractices}
                    supportedPayers={supportedPayers}
                  />
                }
              />
              <Route
                path="/reconciliation/*"
                element={
                  <ReconciliationDashboard
                    store={reconciliationsStore}
                    updateStore={updateReconciliationsStore}
                    queryClient={queryClient}
                    loadPractices={loadPractices}
                    practices={practices}
                    updateSnackBar={updateSnackBar}
                  />
                }
              />
              <Route
                path="/reports/*"
                element={<ReportsDashboard queryClient={queryClient} />}
              />
              <Route
                path="/credentials"
                element={
                  <CredentialsDashboard
                    practices={practices}
                    updateSnackBar={updateSnackBar}
                    supportedSources={supportedSources}
                    selectedPractices={selectedPractices}
                  />
                }
              />
              <Route
                path="/CopilotAssist"
                element={
                  <CopilotAssist
                    fullname={user && user.name ? user.name : ""}
                    selectedPractices={selectedPractices}
                  />
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        ) : (
          <Loading />
        )}
      </LeftNav>
    </div>
  );
}

export default (() => {
  if (USE_AUTH) {
    return withAuthenticationRequired(FCPortal);
  }
  return FCPortal;
})();
