import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { PREDEFINED_CLAIM_FILTERS } from "../../status";
import useDevMode from "../../utils/devmode";
import ClaimsTable from "../ClaimsTable";

interface ClaimsListViewProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  loading: boolean;
  updateClaim: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  onSaveClaim: (claim: ClaimMessage) => Promise<void>;
  onDeleteClaim: (claim: ClaimWithProcedureAndPatientMessage) => Promise<void>;
  onSaveProcedure: (procedure: ProcedureMessage) => Promise<void>;
  onDeleteProcedure: (procedure: ProcedureMessage) => Promise<void>;
  postToPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  batchPostPMS: () => void;
  isBatchPosting: boolean;
  reset: () => void;
  selectedPractices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
  selectedFilter: PREDEFINED_CLAIM_FILTERS;
  setSelectedFilter: (filter: PREDEFINED_CLAIM_FILTERS) => void;
}

export default function ClaimsListView({
  claims,
  loading,
  updateClaim,
  onSaveClaim,
  onDeleteClaim,
  onSaveProcedure,
  onDeleteProcedure,
  postToPMS,
  batchPostPMS,
  isBatchPosting,
  reset,
  selectedPractices,
  supportedPayers,
  selectedFilter,
  setSelectedFilter,
}: ClaimsListViewProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const devMode = useDevMode();

  const filterFromQueryParam = (): PREDEFINED_CLAIM_FILTERS => {
    const statusParam = searchParams.get("status");
    if (!statusParam) {
      return PREDEFINED_CLAIM_FILTERS.ALL_CLAIMS;
    }
    const formattedStatus =
      statusParam.charAt(0).toUpperCase() + statusParam.slice(1).toLowerCase();
    return Object.values(PREDEFINED_CLAIM_FILTERS).includes(
      formattedStatus as PREDEFINED_CLAIM_FILTERS,
    )
      ? (formattedStatus as PREDEFINED_CLAIM_FILTERS)
      : PREDEFINED_CLAIM_FILTERS.ALL_CLAIMS;
  };

  useEffect(() => {
    setSelectedFilter(filterFromQueryParam());
    return reset;
  }, []);

  return (
    <>
      <h1>Claims</h1>
      {devMode ? (
        <div>
          <Button
            variant="contained"
            onClick={batchPostPMS}
            disabled={isBatchPosting}
            sx={{ width: "auto", minWidth: "7.5rem", marginBottom: "1rem" }}
          >
            {!isBatchPosting ? (
              "Batch Post"
            ) : (
              <CircularProgress color="inherit" size={24} />
            )}
          </Button>
        </div>
      ) : null}
      <ToggleButtonGroup
        size="medium"
        color="primary"
        value={selectedFilter}
        exclusive
        onChange={(_, newfilter) => {
          if (newfilter && selectedFilter !== newfilter) {
            const params = new URLSearchParams(location.search);
            params.set("status", newfilter.toLowerCase());
            navigate({ search: params.toString() }, { replace: true });
            setSelectedFilter(newfilter);
          }
        }}
        aria-label="Claim Predefine Filters"
      >
        {Object.values(PREDEFINED_CLAIM_FILTERS).map((filter) => (
          <ToggleButton
            key={filter}
            value={filter}
            aria-label={`${filter} filter`}
          >
            {filter}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "27px", padding: "0px 2px" }}
      >
        <Grid item xs={12}>
          <ClaimsTable
            initialSortingModel={
              [
                PREDEFINED_CLAIM_FILTERS.REVIEW,
                PREDEFINED_CLAIM_FILTERS.DENIALS,
              ].includes(selectedFilter)
                ? [
                  { field: "availableSince", sort: "asc" },
                  { field: "urgency", sort: "desc" },
                ]
                : [
                  { field: "postedDateTime", sort: "desc" },
                  { field: "urgency", sort: "desc" },
                  { field: "availableSince", sort: "desc" },
                ]
            }
            payment={null}
            enablePagination
            enablePaymentNavigation
            claims={claims}
            isLoading={loading}
            onClaimUpdate={updateClaim}
            onPostToPMS={postToPMS}
            enableClaimDetails
            excludeByFieldNames={["paymentId", "notes"]}
            sortColumnOrder={[
              "urgency",
              "claimStatus",
              "availableSince",
              "practiceDisplayName",
              "payer",
              "wieldyPatientName",
              "claimId",
              "wieldyClaimDate",
              "claimPayerPays",
              "sources",
              "action",
              "postedState",
              "postedDateTime",
            ]}
            onSaveClaim={onSaveClaim}
            onDeleteClaim={onDeleteClaim}
            onSaveProcedure={onSaveProcedure}
            onDeleteProcedure={onDeleteProcedure}
            practices={selectedPractices}
            supportedPayers={supportedPayers}
            persistKey="claimsListView"
          />
        </Grid>
      </Grid>
    </>
  );
}
