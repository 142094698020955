import "./PaymentsDetailsView.css";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { formatMoney } from "../../formatters";
import { isoToDate } from "../../utils/utils";
import ClaimsTable from "../ClaimsTable";
import Loading from "../Loading";
import PDFViewer from "../PDFViewer";
import { redactText } from "../redact";

function PaymentHeader(selectedPayment: PaymentMessage) {
  const {
    payerPaymentId,
    eopPaymentAmount,
    paymentDate,
    practiceDisplayName,
    paymentType,
  } = selectedPayment;
  return (
    <table>
      <thead>
        <tr>
          <td
            className="paymentHeaderCell"
            style={{ borderLeft: "0px", paddingLeft: "0px" }}
          >
            <Typography className="paymentHeaderTypography">
              <span className="paymentHeaderKey">Practice: </span>
              <span style={{ fontWeight: "600" }}>{practiceDisplayName}</span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span className="paymentHeaderKey">Payment ID: </span>
              <span style={{ fontWeight: "600" }} data-sr-redact>
                {redactText(payerPaymentId)}
              </span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span>Payment Amount: </span>
              <span style={{ fontWeight: "600" }} data-sr-redact>
                {formatMoney(eopPaymentAmount)}
              </span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span className="paymentHeaderKey">Payment Date: </span>
              <span style={{ fontWeight: "600" }}>
                {paymentDate
                  ? isoToDate(paymentDate).toLocaleDateString()
                  : "-"}
              </span>
            </Typography>
          </td>
          {paymentType && (
            <td className="paymentHeaderCell">
              <Typography
                className="paymentHeaderTypography"
                variant="subtitle1"
              >
                <span className="paymentHeaderKey">Payment Type: </span>
                <span style={{ fontWeight: "600" }}>{paymentType}</span>
              </Typography>
            </td>
          )}
        </tr>
      </thead>
    </table>
  );
}

interface PaymentsDetailsViewProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  updateClaim: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  postToPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  payment: PaymentMessage | null;
  loadPayment: (paymentId: string) => Promise<void>;
  loadingClaims: boolean;
  reset: () => void;
  practices: PracticeMessage[];
  onSaveProcedure: (procedure: ProcedureMessage) => Promise<void>;
  onDeleteProcedure: (procedure: ProcedureMessage) => Promise<void>;
  onSaveClaim: (claim: ClaimMessage, file: File | null) => Promise<void>;
  onDeleteClaim: (claim: ClaimWithProcedureAndPatientMessage) => Promise<void>;
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function PaymentsDetailsView({
  claims,
  updateClaim,
  onSaveProcedure,
  onDeleteProcedure,
  onSaveClaim,
  onDeleteClaim,
  postToPMS,
  payment,
  loadPayment,
  loadingClaims,
  reset,
  practices,
  supportedPayers,
}: PaymentsDetailsViewProps) {
  const { paymentId } = useParams();
  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    }
    return reset;
  }, []);

  if (!payment) {
    return (
      <div
        style={{
          display: "flex",
          height: "80vh",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <h1 style={{ marginBottom: "12px" }}>{payment.payer}</h1>
      {PaymentHeader(payment)}
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "27px",
          padding: "0px 2px",
        }}
      >
        <Grid item xs={12}>
          <ClaimsTable
            payment={payment}
            practices={practices}
            claims={claims}
            isLoading={loadingClaims}
            onClaimUpdate={updateClaim}
            onPostToPMS={postToPMS}
            enableClaimDetails
            initialSortingModel={[
              { field: "urgency", sort: "desc" },
              { field: "wieldyPatientName", sort: "asc" },
            ]}
            excludeByFieldNames={[
              "urgency",
              "payer",
              "paymentId",
              "practiceDisplayName",
              "notes",
              "documents",
            ]}
            sortColumnOrder={[
              "claimStatus",
              "availableSince",
              "wieldyPatientName",
              "claimId",
              "wieldyClaimDate",
              "claimPayerPays",
              "action",
              "postedState",
              "postedDateTime",
            ]}
            onSaveClaim={onSaveClaim}
            onDeleteClaim={onDeleteClaim}
            onSaveProcedure={onSaveProcedure}
            onDeleteProcedure={onDeleteProcedure}
            supportedPayers={supportedPayers}
            persistKey="paymentDetailsView"
          />
        </Grid>
        <Grid item xs={12}>
          <PDFViewer selectedPayment={payment} />
        </Grid>
      </Grid>
    </>
  );
}
